import { InjectionToken } from "@angular/core";

export const COUNTRY_CODES = new InjectionToken<{ [key: string]: string }>('CountryCodes', {

	factory: () => {
		return {
			'UA': $localize`:nationality:Ukraine`,
			'AF': $localize`:nationality:Afghanistan`,
			'AX': $localize`:nationality:Aland Islands`,
			'AL': $localize`:nationality:Albania`,
			'DZ': $localize`:nationality:Algeria`,
			'AS': $localize`:nationality:American Samoa`,
			'AD': $localize`:nationality:Andorra`,
			'AO': $localize`:nationality:Angola`,
			'AI': $localize`:nationality:Anguilla`,
			'AQ': $localize`:nationality:Antarctica`,
			'AG': $localize`:nationality:Antigua And Barbuda`,
			'AR': $localize`:nationality:Argentina`,
			'AM': $localize`:nationality:Armenia`,
			'AW': $localize`:nationality:Aruba`,
			'AU': $localize`:nationality:Australia`,
			'AT': $localize`:nationality:Austria`,
			'AZ': $localize`:nationality:Azerbaijan`,
			'BS': $localize`:nationality:Bahamas`,
			'BH': $localize`:nationality:Bahrain`,
			'BD': $localize`:nationality:Bangladesh`,
			'BB': $localize`:nationality:Barbados`,
			'BY': $localize`:nationality:Belarus`,
			'BE': $localize`:nationality:Belgium`,
			'BZ': $localize`:nationality:Belize`,
			'BJ': $localize`:nationality:Benin`,
			'BM': $localize`:nationality:Bermuda`,
			'BT': $localize`:nationality:Bhutan`,
			'BO': $localize`:nationality:Bolivia`,
			'BA': $localize`:nationality:Bosnia And Herzegovina`,
			'BW': $localize`:nationality:Botswana`,
			'BV': $localize`:nationality:Bouvet Island`,
			'BR': $localize`:nationality:Brazil`,
			'IO': $localize`:nationality:British Indian Ocean Territory`,
			'BN': $localize`:nationality:Brunei Darussalam`,
			'BG': $localize`:nationality:Bulgaria`,
			'BF': $localize`:nationality:Burkina Faso`,
			'BI': $localize`:nationality:Burundi`,
			'KH': $localize`:nationality:Cambodia`,
			'CM': $localize`:nationality:Cameroon`,
			'CA': $localize`:nationality:Canada`,
			'CV': $localize`:nationality:Cape Verde`,
			'KY': $localize`:nationality:Cayman Islands`,
			'CF': $localize`:nationality:Central African Republic`,
			'TD': $localize`:nationality:Chad`,
			'CL': $localize`:nationality:Chile`,
			'CN': $localize`:nationality:China`,
			'CX': $localize`:nationality:Christmas Island`,
			'CC': $localize`:nationality:Cocos (Keeling) Islands`,
			'CO': $localize`:nationality:Colombia`,
			'KM': $localize`:nationality:Comoros`,
			'CG': $localize`:nationality:Congo`,
			'CD': $localize`:nationality:Congo, Democratic Republic`,
			'CK': $localize`:nationality:Cook Islands`,
			'CR': $localize`:nationality:Costa Rica`,
			'CI': $localize`:nationality:Cote D\'Ivoire`,
			'HR': $localize`:nationality:Croatia`,
			'CU': $localize`:nationality:Cuba`,
			'CY': $localize`:nationality:Cyprus`,
			'CZ': $localize`:nationality:Czech Republic`,
			'DK': $localize`:nationality:Denmark`,
			'DJ': $localize`:nationality:Djibouti`,
			'DM': $localize`:nationality:Dominica`,
			'DO': $localize`:nationality:Dominican Republic`,
			'EC': $localize`:nationality:Ecuador`,
			'EG': $localize`:nationality:Egypt`,
			'SV': $localize`:nationality:El Salvador`,
			'GQ': $localize`:nationality:Equatorial Guinea`,
			'ER': $localize`:nationality:Eritrea`,
			'EE': $localize`:nationality:Estonia`,
			'ET': $localize`:nationality:Ethiopia`,
			'FK': $localize`:nationality:Falkland Islands (Malvinas)`,
			'FO': $localize`:nationality:Faroe Islands`,
			'FJ': $localize`:nationality:Fiji`,
			'FI': $localize`:nationality:Finland`,
			'FR': $localize`:nationality:France`,
			'GF': $localize`:nationality:French Guiana`,
			'PF': $localize`:nationality:French Polynesia`,
			'TF': $localize`:nationality:French Southern Territories`,
			'GA': $localize`:nationality:Gabon`,
			'GM': $localize`:nationality:Gambia`,
			'GE': $localize`:nationality:Georgia`,
			'DE': $localize`:nationality:Germany`,
			'GH': $localize`:nationality:Ghana`,
			'GI': $localize`:nationality:Gibraltar`,
			'GR': $localize`:nationality:Greece`,
			'GL': $localize`:nationality:Greenland`,
			'GD': $localize`:nationality:Grenada`,
			'GP': $localize`:nationality:Guadeloupe`,
			'GU': $localize`:nationality:Guam`,
			'GT': $localize`:nationality:Guatemala`,
			'GG': $localize`:nationality:Guernsey`,
			'GN': $localize`:nationality:Guinea`,
			'GW': $localize`:nationality:Guinea-Bissau`,
			'GY': $localize`:nationality:Guyana`,
			'HT': $localize`:nationality:Haiti`,
			'HM': $localize`:nationality:Heard Island & Mcdonald Islands`,
			'VA': $localize`:nationality:Holy See (Vatican City State)`,
			'HN': $localize`:nationality:Honduras`,
			'HK': $localize`:nationality:Hong Kong`,
			'HU': $localize`:nationality:Hungary`,
			'IS': $localize`:nationality:Iceland`,
			'IN': $localize`:nationality:India`,
			'ID': $localize`:nationality:Indonesia`,
			'IR': $localize`:nationality:Iran, Islamic Republic Of`,
			'IQ': $localize`:nationality:Iraq`,
			'IE': $localize`:nationality:Ireland`,
			'IM': $localize`:nationality:Isle Of Man`,
			'IL': $localize`:nationality:Israel`,
			'IT': $localize`:nationality:Italy`,
			'JM': $localize`:nationality:Jamaica`,
			'JP': $localize`:nationality:Japan`,
			'JE': $localize`:nationality:Jersey`,
			'JO': $localize`:nationality:Jordan`,
			'KZ': $localize`:nationality:Kazakhstan`,
			'KE': $localize`:nationality:Kenya`,
			'KI': $localize`:nationality:Kiribati`,
			'KR': $localize`:nationality:Korea`,
			'KW': $localize`:nationality:Kuwait`,
			'KG': $localize`:nationality:Kyrgyzstan`,
			'LA': $localize`:nationality:Lao People\'s Democratic Republic`,
			'LV': $localize`:nationality:Latvia`,
			'LB': $localize`:nationality:Lebanon`,
			'LS': $localize`:nationality:Lesotho`,
			'LR': $localize`:nationality:Liberia`,
			'LY': $localize`:nationality:Libyan Arab Jamahiriya`,
			'LI': $localize`:nationality:Liechtenstein`,
			'LT': $localize`:nationality:Lithuania`,
			'LU': $localize`:nationality:Luxembourg`,
			'MO': $localize`:nationality:Macao`,
			'MK': $localize`:nationality:Macedonia`,
			'MG': $localize`:nationality:Madagascar`,
			'MW': $localize`:nationality:Malawi`,
			'MY': $localize`:nationality:Malaysia`,
			'MV': $localize`:nationality:Maldives`,
			'ML': $localize`:nationality:Mali`,
			'MT': $localize`:nationality:Malta`,
			'MH': $localize`:nationality:Marshall Islands`,
			'MQ': $localize`:nationality:Martinique`,
			'MR': $localize`:nationality:Mauritania`,
			'MU': $localize`:nationality:Mauritius`,
			'YT': $localize`:nationality:Mayotte`,
			'MX': $localize`:nationality:Mexico`,
			'FM': $localize`:nationality:Micronesia, Federated States Of`,
			'MD': $localize`:nationality:Moldova`,
			'MC': $localize`:nationality:Monaco`,
			'MN': $localize`:nationality:Mongolia`,
			'ME': $localize`:nationality:Montenegro`,
			'MS': $localize`:nationality:Montserrat`,
			'MA': $localize`:nationality:Morocco`,
			'MZ': $localize`:nationality:Mozambique`,
			'MM': $localize`:nationality:Myanmar`,
			'NA': $localize`:nationality:Namibia`,
			'NR': $localize`:nationality:Nauru`,
			'NP': $localize`:nationality:Nepal`,
			'NL': $localize`:nationality:Netherlands`,
			'AN': $localize`:nationality:Netherlands Antilles`,
			'NC': $localize`:nationality:New Caledonia`,
			'NZ': $localize`:nationality:New Zealand`,
			'NI': $localize`:nationality:Nicaragua`,
			'NE': $localize`:nationality:Niger`,
			'NG': $localize`:nationality:Nigeria`,
			'NU': $localize`:nationality:Niue`,
			'NF': $localize`:nationality:Norfolk Island`,
			'MP': $localize`:nationality:Northern Mariana Islands`,
			'NO': $localize`:nationality:Norway`,
			'OM': $localize`:nationality:Oman`,
			'PK': $localize`:nationality:Pakistan`,
			'PW': $localize`:nationality:Palau`,
			'PS': $localize`:nationality:Palestinian Territory, Occupied`,
			'PA': $localize`:nationality:Panama`,
			'PG': $localize`:nationality:Papua New Guinea`,
			'PY': $localize`:nationality:Paraguay`,
			'PE': $localize`:nationality:Peru`,
			'PH': $localize`:nationality:Philippines`,
			'PN': $localize`:nationality:Pitcairn`,
			'PL': $localize`:nationality:Poland`,
			'PT': $localize`:nationality:Portugal`,
			'PR': $localize`:nationality:Puerto Rico`,
			'QA': $localize`:nationality:Qatar`,
			'RE': $localize`:nationality:Reunion`,
			'RO': $localize`:nationality:Romania`,
			'RU': $localize`:nationality:Russian Federation`,
			'RW': $localize`:nationality:Rwanda`,
			'BL': $localize`:nationality:Saint Barthelemy`,
			'SH': $localize`:nationality:Saint Helena`,
			'KN': $localize`:nationality:Saint Kitts And Nevis`,
			'LC': $localize`:nationality:Saint Lucia`,
			'MF': $localize`:nationality:Saint Martin`,
			'PM': $localize`:nationality:Saint Pierre And Miquelon`,
			'VC': $localize`:nationality:Saint Vincent And Grenadines`,
			'WS': $localize`:nationality:Samoa`,
			'SM': $localize`:nationality:San Marino`,
			'ST': $localize`:nationality:Sao Tome And Principe`,
			'SA': $localize`:nationality:Saudi Arabia`,
			'SN': $localize`:nationality:Senegal`,
			'RS': $localize`:nationality:Serbia`,
			'SC': $localize`:nationality:Seychelles`,
			'SL': $localize`:nationality:Sierra Leone`,
			'SG': $localize`:nationality:Singapore`,
			'SK': $localize`:nationality:Slovakia`,
			'SI': $localize`:nationality:Slovenia`,
			'SB': $localize`:nationality:Solomon Islands`,
			'SO': $localize`:nationality:Somalia`,
			'ZA': $localize`:nationality:South Africa`,
			'GS': $localize`:nationality:South Georgia And Sandwich Isl.`,
			'ES': $localize`:nationality:Spain`,
			'LK': $localize`:nationality:Sri Lanka`,
			'SD': $localize`:nationality:Sudan`,
			'SR': $localize`:nationality:Suriname`,
			'SJ': $localize`:nationality:Svalbard And Jan Mayen`,
			'SZ': $localize`:nationality:Swaziland`,
			'SE': $localize`:nationality:Sweden`,
			'CH': $localize`:nationality:Switzerland`,
			'SY': $localize`:nationality:Syrian Arab Republic`,
			'TW': $localize`:nationality:Taiwan`,
			'TJ': $localize`:nationality:Tajikistan`,
			'TZ': $localize`:nationality:Tanzania`,
			'TH': $localize`:nationality:Thailand`,
			'TL': $localize`:nationality:Timor-Leste`,
			'TG': $localize`:nationality:Togo`,
			'TK': $localize`:nationality:Tokelau`,
			'TO': $localize`:nationality:Tonga`,
			'TT': $localize`:nationality:Trinidad And Tobago`,
			'TN': $localize`:nationality:Tunisia`,
			'TR': $localize`:nationality:Turkey`,
			'TM': $localize`:nationality:Turkmenistan`,
			'TC': $localize`:nationality:Turks And Caicos Islands`,
			'TV': $localize`:nationality:Tuvalu`,
			'UG': $localize`:nationality:Uganda`,
			'AE': $localize`:nationality:United Arab Emirates`,
			'GB': $localize`:nationality:United Kingdom`,
			'US': $localize`:nationality:United States`,
			'UM': $localize`:nationality:United States Outlying Islands`,
			'UY': $localize`:nationality:Uruguay`,
			'UZ': $localize`:nationality:Uzbekistan`,
			'VU': $localize`:nationality:Vanuatu`,
			'VE': $localize`:nationality:Venezuela`,
			'VN': $localize`:nationality:Viet Nam`,
			'VG': $localize`:nationality:Virgin Islands, British`,
			'VI': $localize`:nationality:Virgin Islands, U.S.`,
			'WF': $localize`:nationality:Wallis And Futuna`,
			'EH': $localize`:nationality:Western Sahara`,
			'YE': $localize`:nationality:Yemen`,
			'ZM': $localize`:nationality:Zambia`,
			'ZW': $localize`:nationality:Zimbabwe`
		}
	}

});