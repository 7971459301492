<div class="resort-list-select">
	<div
		class="country-select"
		[ngClass]="{'hidden': resortList.length !== 0}"
	>
		<ul>
			<li
				*ngFor="let country of countriesList"
				(click)="handleCountrySelected(country)"
				class="menu-item"
			>
				<span class="flag-icon flag-icon-{{country.code}}"></span>
				{{country.title}}
			</li>
		</ul>
	</div>
	<div
		class="resort-select"
		*ngIf="resortList.length !== 0"
	>
		<div
			(click)="handleBackButton()"
			i18n="back to countries list"
			class="menu-item"
		>
			<strong>&larr;&nbsp;Back</strong>
		</div>
		<div class="menu-item" (click)="handleResortSelected()">
			All resorts
		</div>
		<div
			*ngFor="let resort of resortList"
			class="menu-item"
			(click)="handleResortSelected(resort)"
		>
			{{resort.title}}
		</div>
	</div>
</div>