import { Pipe, PipeTransform } from '@angular/core';
import { FoodValues } from '../../data/FoodValues';
import { Food } from '../../models/Food';

@Pipe({
	name: 'food'
})
export class FoodPipe implements PipeTransform {

	transform(arg: string): string {
		const value = FoodValues[arg.toUpperCase() as Food];
		return value || arg;
	}

}
