import { Pipe, PipeTransform } from '@angular/core';

const flagsMap: {[key: number]: string} = {
	43: 'eg',
	115: 'tr',
	13: 'bg',
	135: 'me',
	10: 'al',
	8: 'at',
	3: 'ad',
	26: 'hu',
	29: 'vn',
	34: 'gr',
	33: 'ge',
	42: 'do',
	52: 'il',
	46: 'in',
	47: 'id',
	53: 'jo',
	49: 'es',
	48: 'it',
	166: 'qa',
	54: 'cy',
	58: 'cn',
	56: 'cu',
	84: 'mu',
	79: 'mv',
	80: 'mx',
	32: 'nl',
	92: 'ae',
	99: 'pt',
	104: 'sc',
	106: 'sg',
	108: 'sk',
	113: 'th',
	152: 'tz',
	114: 'tn',
	119: 'fr',
	134: 'hr',
	125: 'lk',
	122: 'cz',
	121: 'fi',
	98: 'pl',
	60: 'ke',
	4: 'am',
	159: 'cv'
}

@Pipe({
	name: 'countriesFlag'
})
export class CountriesFlagPipe implements PipeTransform {

	transform(value: number): string {
		return (flagsMap[value]) ? `flag-icon-${flagsMap[value]}` : '';
	}

}
