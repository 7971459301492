import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SearchBase } from '../../models/SearchBase';

@Component({
	selector: 'tours-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent extends SearchBase implements OnInit {

	private homeClass = 'home-page';

	constructor(@Inject(DOCUMENT) private document: Document, public router: Router) {
		super(router);
	}

	ngOnInit(): void {
		this.document.body.classList.add(this.homeClass);
	}

	ngOnDestroy(): void {
		this.document.body.classList.remove(this.homeClass);
	}
}