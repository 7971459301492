import { Component } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TosService, TOS_URL_TOKEN } from './tos.service';

@Component({
	selector: 'lib-tos',
	templateUrl: './tos.component.html',
	styleUrls: ['./tos.component.scss'],
	providers: [
		TosService,
		{
			provide: TOS_URL_TOKEN,
			useFactory: ({ url }: any) => url,
			deps: [MAT_DIALOG_DATA]
		}
	]
})
export class TosComponent {
	readonly tos$ = this.service.get();
	constructor(private service: TosService) { }
}