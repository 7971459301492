import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateRange } from '../../../models/DateRange';

declare const jQuery: any;
declare const moment: any;

@Component({
	selector: 'tours-calentim',
	templateUrl: './calentim.component.html',
	styleUrls: ['./calentim.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: CalentimComponent,//forwardRef(() => CustomerInfoComponent),
			multi: true
		}
	]
})
export class CalentimComponent implements AfterViewInit, ControlValueAccessor {

	@ViewChild('calentim') calentim!: ElementRef;
	@Input() format: string = "YYYY-MM-DD";

	onTouchedCallback: () => void = () => { };
	onChangedCallback: (value: DateRange) => void = () => { };

	calentimEl!: any;

	checkIn!: string;
	checkInTo!: string;

	constructor() { }

	writeValue(obj: DateRange): void {

		this.checkIn = obj.checkIn;
		this.checkInTo = obj.checkInTo;

		if (this.calentim) {
			const instance = jQuery(this.calentim.nativeElement).data("calentim");
		
			instance.config.startDate = moment(obj.checkIn, this.format);
			instance.config.endDate = moment(obj.checkInTo, this.format);
			//NOT DOCUMENTED. WAS USED IN setStart and setEnd in calentim
			instance.refreshValues();
		}
		

	}

	registerOnChange(fn: any): void {
		this.onChangedCallback = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}

	ngAfterViewInit(): void {

		jQuery(this.calentim.nativeElement).calentim({
			"startDate": moment(this.checkIn, this.format),
			"endDate": moment(this.checkInTo, this.format),
			"showHeader": false,
			"showFooter": false,
			"showOn": "bottom",
			"autoAlign": true,
			"startOnMonday": true,
			"showTimePickers": false,
			"continuous": false,
			"autoCloseOnSelect": true,
			"minDate": moment().add(1, 'days'),
			"format": this.format,
			"onafterselect": (calentim: any, startDate: any, endDate: any) => {
				this.onChangedCallback({
					checkIn: startDate.format(this.format),
					checkInTo: endDate.format(this.format),
				});
			},
		});
	}

}