import { InjectionToken } from "@angular/core";
import { Airport } from "../models/Airport";

export const AIRPORTS = new InjectionToken<{ [key: string]: Airport }>('Airport map', {
	factory: () => {
		return AIRPORT_VALUES;
	}
});

const AIRPORT_VALUES: { [key: string]: Airport } = {
	'KBP': {
		name: $localize`:airport:Kyiv Boryspil`,
		code: 'KBP',
		std: '+02:00',
		dst: '+03:00'
	},
	'SSH': {
		name: $localize`:airport:Sharm El Sheikh`,
		code: 'SSH',
		std: '+02:00',
		dst: '+03:00'
	},
	'DXB': {
		name: $localize`:airport:Dubai`,
		code: 'DXB',
		std: '+04:00',
		dst: '+04:00'
	},
	'SHJ': {
		name: $localize`:airport:Sharjah`,
		code: 'SHJ',
		std: '+04:00',
		dst: '+04:00'
	},
	'DWC': {
		name: $localize`:airport:Dubai Al Maktoum`,
		code: 'DWC',
		std: '+04:00',
		dst: '+04:00'
	},
	'RMF': {
		name: $localize`:airport:Marsa Alam`,
		code: 'RMF',
		std: '+02:00',
		dst: '+03:00'
	},
	'BJV': {
		name: $localize`:airport:Bodrum-Milas`,
		code: 'BJV',
		std: '+02:00',
		dst: '+03:00'
	},
	'DLM': {
		name: $localize`:airport:Dalaman`,
		code: 'DLM',
		std: '+02:00',
		dst: '+03:00'
	},
	'ADB': {
		name: $localize`:airport:Izmir Adnan Menderes`,
		code: 'ADB',
		std: '+02:00',
		dst: '+03:00'
	},
	'IST': {
		name: $localize`:airport:Istanbul`,
		code: 'IST',
		std: '+02:00',
		dst: '+03:00'
	},
	'AYT': {
		name: $localize`:airport:Antalya`,
		code: 'AYT',
		std: '+02:00',
		dst: '+03:00'
	},
	'MIR': {
		name: $localize`:airport:Monastir Habib Bourguiba`,
		code: 'MIR',
		std: '+01:00',
		dst: '+01:00'
	},
	'NBE': {
		name: $localize`:airport:Hammamet-Enfidha`,
		code: 'NBE',
		std: '+01:00',
		dst: '+01:00'
	},
	'LCA': {
		name: $localize`:airport:Larnaca`,
		code: 'LCA',
		std: '+02:00',
		dst: '+03:00'
	},
	'PFO': {
		name: $localize`:airport:Paphos`,
		code: 'PFO',
		std: '+02:00',
		dst: '+03:00'
	},
	'AUH': {
		name: $localize`:airport:Abu Dhabi`,
		code: 'AUH',
		std: '+04:00',
		dst: '+04:00'
	},
	'DOH': {
		name: $localize`:airport:Doha-Hamad`,
		code: 'DOH',
		std: '+03:00',
		dst: '+03:00'
	},
	'HAK': {
		name: $localize`:airport:Haikou Meilan`,
		code: 'HAK',
		std: '+08:00',
		dst: '+08:00'
	},
	'SYX': {
		name: $localize`:airport:Sanya Phoenix`,
		code: 'SYX',
		std: '+08:00',
		dst: '+08:00'
	},
	'DPS': {
		name: $localize`:airport:Denpasar Ngurah Rai`,
		code: 'DPS',
		std: '+08:00',
		dst: '+08:00'
	},
	'VRA': {
		name: $localize`:airport:Varadero`,
		code: 'VRA',
		std: '-05:00',
		dst: '-04:00'
	},
	'HAV': {
		name: $localize`:airport:Havana`,
		code: 'HAV',
		std: '-05:00',
		dst: '-04:00'
	},
	'HKT': {
		name: $localize`:airport:Phuket`,
		code: 'HKT',
		std: '+07:00',
		dst: '+07:00'
	},
	'BKK': {
		name: $localize`:airport:Bangkok-Suvarnabhumi`,
		code: 'BKK',
		std: '+07:00',
		dst: '+07:00'
	},
	'MLE': {
		name: $localize`:airport:Male-Velana`,
		code: 'MLE',
		std: '+05:00',
		dst: '+05:00'
	},
	'ZNZ': {
		name: $localize`:airport:Zanzibar`,
		code: 'ZNZ',
		std: '+03:00',
		dst: '+03:00'
	},
	'PYX': {
		name: $localize`:airport:Pattaya`,
		code: 'PYX',
		std: '+07:00',
		dst: '+07:00'
	},
	'LRM': {
		name: $localize`:airport:La Romana`,
		code: 'LRM',
		std: '-04:00',
		dst: '-04:00'
	},
	'PUJ': {
		name: $localize`:airport:Punta Cana`,
		code: 'PUJ',
		std: '-04:00',
		dst: '-04:00'
	},
	'CUN': {
		name: $localize`:airport:Cancun`,
		code: 'CUN',
		std: '-06:00',
		dst: '-05:00'
	},
	'CMB': {
		name: $localize`:airport:Colombo-Bandaranaike`,
		code: 'CMB',
		std: '+05:30',
		dst: '+05:30'
	},
	'HRI': {
		name: $localize`:airport:Mattala`,
		code: 'HRI',
		std: '+05:30',
		dst: '+05:30'
	},
	'SGN': {
		name: $localize`:airport:Ho Chi Minh City Airport`,
		code: 'SGN',
		std: '+07:00',
		dst: '+07:00'
	},
	'GOI': {
		name: $localize`:airport:Goa`,
		code: 'GOI',
		std: '+05:30',
		dst: '+05:30'
	},
	'BVA': {
		name: $localize`:airport:Paris Beauvais`,
		code: 'BVA',
		std: '+01:00',
		dst: '+02:00'
	},
	'CDG': {
		name: $localize`:airport:Paris Charles de Gaulle`,
		code: 'CDG',
		std: '+01:00',
		dst: '+02:00'
	},
	'ORY': {
		name: $localize`:airport:Paris Orly `,
		std: '+01:00',
		dst: '+02:00',
		code: 'ORY'
	},
	'ALC': {
		name: $localize`:airport:Alicante`,
		std: '+01:00',
		dst: '+02:00',
		code: 'ALC'
	},
	'TFS': {
		name: $localize`:airport:Tenerife South`,
		std: '+00:00',
		dst: '+01:00',
		code: 'TFS'
	},
	'BCN': {
		name: $localize`:airport:Barcelona El Prat`,
		std: '+01:00',
		dst: '+02:00',
		code: 'BCN'
	},
	'REU': {
		name: $localize`:airport:Reus`,
		std: '+01:00',
		dst: '+02:00',
		code: 'REU'
	},
	'GRO': {
		name: $localize`:airport:Girona-Costa Brava`,
		std: '+01:00',
		dst: '+02:00',
		code: 'GRO'
	},
	'TRN': {
		name: $localize`:airport:Torino`,
		std: '+01:00',
		dst: '+02:00',
		code: 'TRN'
	},
	'BGY': {
		name: $localize`:airport:Milan Bergamo`,
		std: '+01:00',
		dst: '+02:00',
		code: 'BGY'
	},
	'MXP': {
		name: $localize`:airport:Milan-Malpensa`,
		std: '+01:00',
		dst: '+02:00',
		code: 'MXP'
	},
	'FCO': {
		name: $localize`:airport:Rome-Fiumicino`,
		std: '+01:00',
		dst: '+02:00',
		code: 'FCO'
	},
	'VCE': {
		name: $localize`:airport:Venice Marco Polo`,
		std: '+01:00',
		dst: '+02:00',
		code: 'VCE'
	},
	'CTA': {
		name: $localize`:airport:Catania Fontanarossa`,
		std: '+01:00',
		dst: '+02:00',
		code: 'CTA'
	},
	'PMO': {
		name: $localize`:airport:Palermo`,
		std: '+01:00',
		dst: '+02:00',
		code: 'PMO'
	},
	'NAP': {
		name: $localize`:airport:Naples`,
		std: '+01:00',
		dst: '+02:00',
		code: 'NAP'
	},
	'BLQ': {
		name: $localize`:airport:Bologna Guglielmo Marconi`,
		std: '+01:00',
		dst: '+02:00',
		code: 'BLQ'
	},
	'CIA': {
		name: $localize`:airport:Rome Ciampino`,
		std: '+01:00',
		dst: '+02:00',
		code: 'CIA'
	},
	'PSA': {
		name: $localize`:airport:Pisa`,
		std: '+01:00',
		dst: '+02:00',
		code: 'PSA'
	},
	'BRI': {
		name: $localize`:airport:Bari`,
		std: '+01:00',
		dst: '+02:00',
		code: 'BRI'
	},
	'CAG': {
		name: $localize`:airport:Cagliari Elmas`,
		std: '+01:00',
		dst: '+02:00',
		code: 'CAG'
	},
	'AHO': {
		name: $localize`:airport:Alghero-Fertilia`,
		std: '+01:00',
		dst: '+02:00',
		code: 'AHO'
	},
	'VRN': {
		name: $localize`:airport:Verona`,
		std: '+01:00',
		dst: '+02:00',
		code: 'VRN'
	},
	'TSF': {
		name: $localize`:airport:Venice-Treviso`,
		std: '+01:00',
		dst: '+02:00',
		code: 'TSF'
	},
	'SUF': {
		name: $localize`:airport:Lamezia-Terme Calabria`,
		std: '+01:00',
		dst: '+02:00',
		code: 'SUF'
	},
	'FLR': {
		name: $localize`:airport:Florence`,
		std: '+01:00',
		dst: '+02:00',
		code: 'FLR'
	},
	'GOA': {
		name: $localize`:airport:Genoa`,
		std: '+01:00',
		dst: '+02:00',
		code: 'GOA'
	},
	'AOI': {
		name: $localize`:airport:Ancona-Marche`,
		std: '+01:00',
		dst: '+02:00',
		code: 'AOI'
	},
	'RMI': {
		name: $localize`:airport:Rimini`,
		std: '+01:00',
		dst: '+02:00',
		code: 'RMI'
	},
	'HER': {
		name: $localize`:airport:Heraklion-Crete`,
		std: '+02:00',
		dst: '+03:00',
		code: 'HER'
	},
	'RHO': {
		name: $localize`:airport:Rhodes`,
		std: '+02:00',
		dst: '+03:00',
		code: 'RHO'
	},
	'SKG': {
		name: $localize`:airport:Thessaloniki`,
		std: '+02:00',
		dst: '+03:00',
		code: 'SKG'
	},
	'ATH': {
		name: $localize`:airport:Athens`,
		std: '+02:00',
		dst: '+03:00',
		code: 'ATH'
	},
	'ZTH': {
		name: $localize`:airport:Zakynthos`,
		std: '+02:00',
		dst: '+03:00',
		code: 'ZTH'
	},
	'CFU': {
		name: $localize`:airport:Corfu`,
		std: '+02:00',
		dst: '+03:00',
		code: 'CFU'
	},
	'GPA': {
		name: $localize`:airport:Araxos Patras`,
		std: '+02:00',
		dst: '+03:00',
		code: 'GPA'
	},
	'KLX': {
		name: $localize`:airport:Kalamata`,
		std: '+02:00',
		dst: '+03:00',
		code: 'KLX'
	},
	'PUY': {
		name: $localize`:airport:Pula`,
		std: '+01:00',
		dst: '+02:00',
		code: 'PUY'
	},
	'DBV': {
		name: $localize`:airport:Dubrovnik`,
		std: '+01:00',
		dst: '+02:00',
		code: 'DBV'
	},
	'ZAD': {
		name: $localize`:airport:Zadar`,
		std: '+01:00',
		dst: '+02:00',
		code: 'ZAD'
	},
	'SPU': {
		name: $localize`:airport:Split`,
		std: '+01:00',
		dst: '+02:00',
		code: 'SPU'
	},
	'TIV': {
		name: $localize`:airport:Tivat`,
		std: '+01:00',
		dst: '+02:00',
		code: 'TIV'
	},
	'TIA': {
		name: $localize`:airport:Tirana`,
		std: '+01:00',
		dst: '+02:00',
		code: 'TIA'
	},
	'PRG': {
		name: $localize`:airport:Prague`,
		std: '+01:00',
		dst: '+02:00',
		code: 'PRG'
	},
	'PED': {
		name: $localize`:airport:Pardubice-Prague`,
		std: '+01:00',
		dst: '+02:00',
		code: 'PED'
	},
	'BUD': {
		name: $localize`:airport:Budapest Ferenc Liszt`,
		std: '+01:00',
		dst: '+02:00',
		code: 'BUD'
	},
	'VAR': {
		name: $localize`:airport:Varna`,
		std: '+02:00',
		dst: '+03:00',
		code: 'VAR'
	},
	'BOJ': {
		name: $localize`:airport:Burgas`,
		std: '+02:00',
		dst: '+03:00',
		code: 'BOJ'
	},
	'SOF': {
		name: $localize`:airport:Sofia`,
		std: '+02:00',
		dst: '+03:00',
		code: 'SOF'
	},
	'KUT': {
		name: $localize`:airport:Kutaisi`,
		std: '+04:00',
		dst: '+04:00',
		code: 'KUT'
	},
	'BUS': {
		name: $localize`:airport:Batumi`,
		std: '+04:00',
		dst: '+04:00',
		code: 'BUS'
	},
	'TBS': {
		name: $localize`:airport:Tbilisi`,
		std: '+04:00',
		dst: '+04:00',
		code: 'TBS'
	},
	'VIE': {
		name: $localize`:airport:Vienna`,
		std: '+01:00',
		dst: '+02:00',
		code: 'VIE'
	},
	'INN': {
		name: $localize`:airport:Innsbruck`,
		std: '+01:00',
		dst: '+02:00',
		code: 'INN'
	},
	'SZG': {
		name: $localize`:airport:Salzburg`,
		std: '+01:00',
		dst: '+02:00',
		code: 'SZG'
	},
	'KTT': {
		name: $localize`:airport:Kittilä`,
		std: '+02:00',
		dst: '+03:00',
		code: 'KTT'
	},
	'BTS': {
		name: $localize`:airport:Bratislava`,
		std: '+01:00',
		dst: '+02:00',
		code: 'BTS'
	},
	'TAT': {
		name: $localize`:airport:Poprad-Tatry`,
		std: '+01:00',
		dst: '+02:00',
		code: 'TAT'
	},
	'KRK': {
		name: $localize`:airport:Kraków John Paul II`,
		std: '+01:00',
		dst: '+02:00',
		code: 'KRK'
	},
	'ESB': {
		name: $localize`:airport:Ankara Esenboga`,
		std: '+02:00',
		dst: '+03:00',
		code: 'ESB',
	},
	'SAW': {
		name: $localize`:airport:Istanbul Sabiha Gökçen`,
		std: '+02:00',
		dst: '+03:00',
		code: 'SAW',
	},
	'ASR': {
		name: $localize`:airport:Kayseri Erkilet`,
		std: '+02:00',
		dst: '+03:00',
		code: 'ASR'
	},
	'MAD': {
		name: $localize`:airport:Madrid-Barajas`,
		std: '+01:00',
		dst: '+02:00',
		code: 'MAD'
	},
	'IEV': {
		name: $localize`:airport:Kyiv-Zhuliany Igor Sikorsky `,
		std: '+02:00',
		dst: '+03:00',
		code: 'IEV'
	},
	'ODS': {
		name: $localize`:airport:Odesa`,
		std: '+02:00',
		dst: '+03:00',
		code: 'ODS'
	},
	'LWO': {
		name: $localize`:airport:Lviv Danylo Halytskyi`,
		std: '+02:00',
		dst: '+03:00',
		code: 'LWO'
	},
	'CWC': {
		name: $localize`:airport:Chernivtsi Leonid Kadeniuk`,
		std: '+02:00',
		dst: '+03:00',
		code: 'CWC'
	},
	'NLV': {
		name: $localize`:airport:Mykolaiv`,
		std: '+02:00',
		dst: '+03:00',
		code: 'NLV'
	},
	'OZH': {
		name: $localize`:airport:Zaporizhzhia`,
		std: '+02:00',
		dst: '+03:00',
		code: 'OZH'
	},
	'DNK': {
		name: $localize`:airport:Dnipropetrovsk`,
		std: '+02:00',
		dst: '+03:00',
		code: 'DNK'
	},
	'HRK': {
		name: $localize`:airport:Kharkiv`,
		std: '+02:00',
		dst: '+03:00',
		code: 'HRK'
	},
	'KHE': {
		name: $localize`:airport:Kherson`,
		std: '+02:00',
		dst: '+03:00',
		code: 'KHE'
	},
	'HRG': {
		name: $localize`:airport:Hurghada`,
		std: '+02:00',
		dst: '+03:00',
		code: 'HRG'
	}
}