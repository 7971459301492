<div class="radio-wrap">
	<div *ngFor="let value of values" (click)="handleClick(value.value)">
		<div			
			class="radio-container"
			[ngClass]="{'active': currentValue === value.value, 'small': size === 'small' }"
		>
			<div class="checkmark"></div>
		</div>
		<div class="label">{{value.label}}</div>
	</div>
</div>