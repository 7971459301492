<div class="home">
	<div class="text-center">
		<h1 i18n="@@index.header">Tours search</h1>
		<h3 i18n="@@index.subheader">of all tour operators</h3>
	</div>
	<div class="grid-container">
		<div class="grid-x align-center">
			<div class="small-12 medium-11 large-10 cell">
				<tours-search-bar (search)="doSearch($event)"></tours-search-bar>
			</div>
		</div>
	</div>
</div>