export class People {
	adults: number;
	children: number[];

	constructor(adults: any, children: any) {

		this.adults = parseInt(adults);
		if (isNaN(this.adults)) {
			console.warn(`Cannot parse adults number ${this.adults}, setting default value 2`);
			this.adults = 2;
		}

		if (Array.isArray(children)) {
			this.children = children.filter(age => !!age).map(age => parseInt(age)).filter(age => !isNaN(age));
		} else if (!!children) {
			this.children = [parseInt(children)];
		} else {
			this.children = [];
		}
	}

	static getNumberOfSeats(people: People) {
		return +people.adults + people.children.filter(age => age > 1).length;
	}

	static toOtpuskFormat(people: People): string {

		const orderedAges = [...people.children]
			.sort((x, y) => x - y)
			.map(age => age.toString())
			.map(age => age.padStart(2, "0"))
			.join('');

		return +people.adults + orderedAges;
	}
}