import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface RadioValues {
	value: string;
	label: string;
}

@Component({
	selector: 'app-beautiful-radio',
	templateUrl: './beautiful-radio.component.html',
	styleUrls: ['./beautiful-radio.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => BeautifulRadioComponent),
			multi: true
		}
	]
})
export class BeautifulRadioComponent implements ControlValueAccessor {

	@Input() size: "small" | "large" = "small";
	@Input() values!: RadioValues[];
	currentValue: string | undefined;

	onTouchedCallback: () => void = () => { };
	onChangeCallback: (value: string) => void = () => { };

	writeValue(obj: any): void {
		this.currentValue = obj;
	}

	registerOnChange(fn: any): void {
		this.onChangeCallback = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}

	handleClick(value: string){
		this.currentValue = value;
		this.onChangeCallback(value);
		this.onTouchedCallback();
	}
}
