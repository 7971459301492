import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FoodPipe } from './pipes/food.pipe';
import { TourOperatorsPipe } from './pipes/tour-operators.pipe';
import { FlightComponent } from './components/flight/flight.component';
import { FlightDetailsComponent } from './components/flight-details/flight-details.component';



@NgModule({
	declarations: [
		FoodPipe,
		TourOperatorsPipe,
		FlightComponent,
		FlightDetailsComponent
	],
	imports: [
		CommonModule
	],
	exports: [
		FoodPipe,
		TourOperatorsPipe,
		FlightComponent
	]
})
export class SharedModule { }
