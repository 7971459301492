<div class="currency-select">
	<select>
		<option
			*ngFor="let currencyOption of currencyList"
			(click)="setCurrency(currencyOption)"
			[attr.selected]="(selectedCurrency | async) === currencyOption  ? true : null"
		>
			{{currencyOption}}
		</option>
	</select>
</div>