import { Component, Input } from '@angular/core';
import { OfferData } from '../../services/offer.service';

@Component({
	selector: 'tours-tour-details',
	templateUrl: './tour-details.component.html',
	styleUrls: ['./tour-details.component.scss']
})
export class TourDetailsComponent {

	@Input() data!: OfferData;

	constructor() { }


}
