export class SearchState {
	constructor(public hash: string, public offset: number = 0){}

	nextBatch(): SearchState {
		return new SearchState(this.hash, this.offset + 1);
	}

	clone() {
		return new SearchState(this.hash, this.offset);
	}
}