import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

	links = [
		{
			text: "Главная",
			url: "//tours.com.ua"
		}, {
			text: "Отзывы",
			url: "//clevertravel.com.ua/otzyvy/"
		}, {
			text: "Контакты",
			url: "//clevertravel.com.ua/contacts/"
		}
	];

	menuVisible: boolean = false;

	constructor(public dialog: MatDialog) { }

	ngOnInit(): void {
	}

	openContactsDialog(){
		this.dialog.open(ContactsDialog);
	}
}

@Component({
	selector: 'app-contacts-dialog',
	template: `
		<div class="contact-modal-content text-center">
			<h3>Контакты</h3>
			<div>
				<a href="tel:+380442228879" style="font-size: 1.3em;">+38 (044) 222-88-79</a>
			</div>
			<div class="clever-phone-info">пн-пт с 11:00 до 18:00 </div>
		</div>
	`
})
export class ContactsDialog {}
