import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { PaymentMethod } from '../../../models/PaymentMethod';
import { PAYMENT_METHOD_GLOSSARY } from '../../injectables/PaymentMethodGlossary';
import { PackageContract } from '../../models/PackageContract';
import { PaymentService } from '../../services/payment.service';

@Component({
	selector: 'tours-payment-options',
	templateUrl: './payment-options.component.html',
	styleUrls: ['./payment-options.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: PaymentOptionsComponent,//forwardRef(() => CustomerInfoComponent),
			multi: true
		}/*, {
			provide: NG_VALIDATORS,
			useExisting: PassportComponent,//forwardRef(() => CustomerInfoComponent),
			multi: true
		}*/
	]
})
export class PaymentOptionsComponent implements OnInit, OnDestroy, ControlValueAccessor {

	@Input() contract$!: Observable<PackageContract>;

	paymentMethodsArray: PaymentMethod[] = [];
	showPrepayVal: boolean = false;
	minPrepayValue?: number;
	sink = new SubSink();
	loading: boolean = true;

	onChange = (mathod?: PaymentMethod) => {}
	onTouch = () => {}

	selectedPaymentMethod?: PaymentMethod;

	constructor(
		private paymentService: PaymentService,
		@Inject(PAYMENT_METHOD_GLOSSARY) public paymentMethodsGlossary: any
	) { }

	writeValue(obj: any): void {
		this.selectedPaymentMethod = obj;
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouch = fn;
	}

	ngOnInit(): void {
		this.sink.add(
			this.contract$.pipe(
				tap(() => this.loading = true),
				switchMap(value => this.paymentService.getPaymentMethods(value))
			).subscribe(value => {
				this.loading = false;
				this.paymentMethodsArray = value;
				this.minPrepayValue = this.paymentMethodsArray.filter(this.isPrepay).map(value=> value.minPrepay).sort().shift();
				this.updatePaymentMethod();
			})
		)
	}

	ngOnDestroy(): void {
		this.sink.unsubscribe();
	}

	private isPrepay(method: PaymentMethod){
		return method.minPrepay < method.price;
	}

	get paymentMethods(): PaymentMethod[] {
		return this.paymentMethodsArray.filter(value => this.showPrepayVal == this.isPrepay(value));
	}

	public selectPaymentMethod(method?: PaymentMethod){
		this.selectedPaymentMethod = method;
		this.onChange(method);
	}

	public selectShowPrepay(value: boolean){
		this.showPrepayVal = value;
		this.updatePaymentMethod();
	}

	public updatePaymentMethod(){
		this.selectPaymentMethod(
			this.paymentMethods.find(value => value.method === 'liqpay')
		)
	}

}
