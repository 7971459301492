<div class="search-list">
	<div class="grid-container">
		<tours-search-bar (search)="doSearch($event)" [params]="route$ | async"></tours-search-bar>
	</div>
	<div class="grid-container">
		<div>

			<ng-container *ngIf="(errors$ | async) as error">
				{{ error | json }}
			</ng-container>

			<ng-container *ngIf="(searchResult$ | async) as result">

				<div *ngFor="let hotel of result!.hotels">
					<tours-hotel-list-item
						[hotel]="hotel"
						(onOfferSelect)="offerSelected($event)"
						(onFlightSelect)="flightSelected($event)"
					></tours-hotel-list-item>
				</div>

				<lib-load-more-button
					*ngIf="!(loading$ | async ) && result!.canLoadMore"
					(click)="loadMore($event)"
				></lib-load-more-button>

			</ng-container>

			<ng-container *ngIf="(loading$ | async) as loading">
				<app-loading-airplane></app-loading-airplane>
			</ng-container>

		</div>
	</div>
</div>