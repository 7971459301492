import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HotelComponent } from './components/hotel/hotel.component';
import { HttpClientModule } from '@angular/common/http';
import { HotelPhotosComponent } from './components/hotel-photos/hotel-photos.component';
import { MapComponent } from './components/map/map.component';
import { ToursLibModule } from 'tours-lib';
import { HotelDescriptionComponent } from './components/hotel-description/hotel-description.component';
import { PhotosLineComponent } from './components/photos-line/photos-line.component';
import { SearchComponent } from './components/search/search.component';
import { RouterModule } from '@angular/router';
import { SearchBarModule } from '../search-bar/search-bar.module';
import { OfferComponent } from './components/offer/offer.component';
import { SharedModule } from '../shared/shared.module';
import { HotelPageComponent } from './page/hotel-page/hotel-page.component';
import { DaysComponent } from './components/facets/days/days.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HotelSearchService } from './services/hotel-search.service';
import { HOTEL_SEARCH_PROVIDER } from './components/search/search.provider';
import { SEARCH_PROVIDER } from '../shared/providers/routeSearchParamsProvider';
import { HOTEL_PROVIDER } from './page/hotel-page/hotel-page.provider';
import { MatDialogModule } from '@angular/material/dialog';
import { PhotoSwipeDirective } from './directives/photo-swipe.directive';

@NgModule({
	declarations: [
		HotelComponent,
		HotelPhotosComponent,
		MapComponent,
		HotelDescriptionComponent,
		PhotosLineComponent,
		SearchComponent,
		OfferComponent,
		HotelPageComponent,
		DaysComponent,
		PhotoSwipeDirective
	],
	imports: [
		CommonModule,
		HttpClientModule,
		ToursLibModule,
		RouterModule,
		SearchBarModule,
		SharedModule,
		ReactiveFormsModule,
		MatDialogModule
	]
})
export class HotelModule { }