import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from '@angular/forms';
import { COUNTRY_CODES } from '../../injectables/CountryCodes';
import { Sex } from '../../models/Sex';
import { RadioValues } from '../beautiful-radio/beautiful-radio.component';


@Component({
	selector: 'app-passport',
	templateUrl: './passport.component.html',
	styleUrls: ['./passport.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: PassportComponent,//forwardRef(() => CustomerInfoComponent),
			multi: true
		}, {
			provide: NG_VALIDATORS,
			useExisting: PassportComponent,//forwardRef(() => CustomerInfoComponent),
			multi: true
		}
	]
})
export class PassportComponent implements ControlValueAccessor, Validator {

	passportNamePattern: string = "[A-Za-z\- ]+";

	passportFormGroup: FormGroup = this.fb.group({
		name: ['', [Validators.required, Validators.pattern(this.passportNamePattern)]],
		surname: ['', [Validators.required, Validators.pattern(this.passportNamePattern)]],
		passport: ['', [Validators.required]],
		sex: [Sex.FEMALE, [Validators.required]],
		birthday: [null, [Validators.required]],
		issued: ['', [Validators.required]],
		valid: [null, [Validators.required]],
		citizenship: ['UA', [Validators.required]]
	});

	genderTypes: RadioValues[] = [
		{
			value: Sex.MALE,
			label: $localize`:@@gendertype.male:Male`
		}, {
			value: Sex.FEMALE,
			label: $localize`:@@gendertype.male:Female`
		}
	];

	onTouched: () => void = () => { };

	constructor(
		private fb: FormBuilder,
		@Inject(COUNTRY_CODES) public countryCodes: { [key: string]: string }
	) {}

	validate(control: AbstractControl): ValidationErrors | null {
		return this.passportFormGroup.valid
			? null
			: { invalidForm: { valid: false, message: "passport fields are invalid" } };
	}

	writeValue(value: any): void {
		value && this.passportFormGroup.setValue(value, { emitEvent: false });
	}

	registerOnChange(fn: any): void {
		this.passportFormGroup.valueChanges.subscribe(fn);
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	get name(){
		return this.passportFormGroup.get('name') as FormControl;
	}

	get surname(){
		return this.passportFormGroup.get('surname') as FormControl;
	}

	get passport(){
		return this.passportFormGroup.get('passport') as FormControl;
	}

	get sex(){
		return this.passportFormGroup.get('sex') as FormControl;
	}

	get birthday(){
		return this.passportFormGroup.get('birthday') as FormControl;
	}

	get issued(){
		return this.passportFormGroup.get('issued') as FormControl;
	}

	get valid(){
		return this.passportFormGroup.get('valid') as FormControl;
	}

	get citizenship(){
		return this.passportFormGroup.get('citizenship') as FormControl;
	}

}