import { Component, Input, OnInit } from '@angular/core';
import { Note } from '../../../models/Note';

@Component({
	selector: 'tours-notes',
	templateUrl: './notes.component.html',
	styleUrls: ['./notes.component.scss']
})
export class NotesComponent {

	@Input() notes!: Note[];

	constructor() { }

}
