import { Component, EventEmitter, Output } from '@angular/core';

@Component({
	selector: 'lib-load-more-button',
	templateUrl: './load-more-button.component.html',
	styleUrls: ['./load-more-button.component.scss']
})
export class LoadMoreButtonComponent {

	@Output() click = new EventEmitter();

	constructor() { }

	loadMore(){
		this.click.emit();
	}

}
