import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/tours/src/environments/environment';
import { Observable } from 'rxjs';
import { AutocompleteItem } from '../models/AutocompleteItem';

@Injectable()
export class AutocompleteService {

	private readonly autocompleteURL: string = `${environment.apiURL}/autocomplete`;

	constructor(private httpClient: HttpClient) { }

	public request(prefix: string): Observable<AutocompleteItem[]> {
		const httpParams = new HttpParams()
			.append('request', prefix);

		return this.httpClient.get<AutocompleteItem[]>(this.autocompleteURL, {
			params: httpParams,
			responseType: "json"
		});
	}
}
