<div class="search-bar-component">

	<form [formGroup]="searchForm" (submit)="submitSearch()">

		<div class="trip-type">
			<!--<beautiful-radio [values]="tripTypes" formControlName="type"></beautiful-radio>-->
		</div>

		<div class="search-bar grid-x">

			<div class="destination cell small-12 medium-3">
				<app-popover>
					<div class="popover-static" #header>
						<div class="people-select-wrap">
							<label>Destination</label>
							<input [value]="destinationString" type="text" class="input-block" readonly="readonly" />
						</div>
					</div>
					<ng-template #content let-close="close">
						<tours-destination-select formControlName="destination" (close)="close($event)"></tours-destination-select>
					</ng-template>
				</app-popover>
			</div>

			<div class="origin cell small-12 medium-3">
				<app-popover>
					<div class="popover-static" #header>
						<div class="people-select-wrap">
							<label>Origin</label>
							<input [value]="originString" type="text" class="input-block" readonly="readonly" />
						</div>
					</div>
					<ng-template #content let-close="close">
						<tours-origin-select formControlName="origin" (close)="close($event)"></tours-origin-select>
					</ng-template>
				</app-popover>
			</div>

			<div class="departure-date cell small-12 medium-2">
				<tours-calentim formControlName="dateRange"></tours-calentim>
			</div>

			<div class="return-date cell small-12 medium-2">
				<app-popover>
					<div class="popover-static" #header>
						<div class="people-select-wrap">
							<label>Duration</label>
							<input [value]="durationString" type="text" class="input-block" readonly="readonly" />
						</div>
					</div>
					<ng-template #content>
						<tours-duration-select formControlName="duration"></tours-duration-select>
					</ng-template>
				</app-popover>
			</div>

			<div class="travellers cell small-12 medium-2">
				<app-popover>
					<div class="popover-static" #header>
						<label>Tourists</label>
						<input [value]="peopleString" type="text" class="input-block" readonly="readonly" />
					</div>
					<ng-template #content>
						<app-people-select formControlName="people"></app-people-select>
					</ng-template>
				</app-popover>
			</div>

		</div>

		<div class="search-button-wrap">
			<button type="submit" i18n="search bar submit button">Search</button>
		</div>

	</form>
</div>
<div *dev>
	{{ searchForm.value | json }}
</div>