import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormArray, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { passportOptionsValidator } from './passport-select.validation';

@Component({
	selector: 'app-passport-select',
	templateUrl: './passport-select.component.html',
	styleUrls: ['./passport-select.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: PassportSelectComponent,//forwardRef(() => CustomerInfoComponent),
			multi: true
		}, {
			provide: NG_VALIDATORS,
			useExisting: PassportSelectComponent,//forwardRef(() => CustomerInfoComponent),
			multi: true
		}
	]
})
export class PassportSelectComponent implements OnInit, ControlValueAccessor, OnDestroy, Validator {

	readonly passportSelectForm = this.fb.group({
		type: ['instantPassports', [Validators.required]],
		passports: new FormArray([])
	}, { validators: passportOptionsValidator });

	onTouched: () => void = () => { };
	sink: SubSink = new SubSink();

	@Input() adults!: number;
	@Input() children!: number;

	constructor(private fb: FormBuilder) {}

	validate(control: AbstractControl): ValidationErrors | null {
		if (this.passportSelectForm.valid) {
			return null;
		} else {
			return { invalidForm: { valid: false, message: "passport-select field is invalid" } };
		}
	}

	ngOnInit(): void {

		this.sink.add(
			this.passportSelectForm.get('type')!.valueChanges
				.subscribe(value => {
					if (value === 'instantPassports'){
						[...Array(this.adults), ...Array(this.children)]
							.forEach((_, index) => {
								this.passports.push(new FormControl(null))
							});
					} else {
						this.passports.clear();
					}
				})
		);
		
	}

	get passportValuesArray(): FormArray {
		return this.passportSelectForm.controls["passports"] as FormArray;
	}

	ngOnDestroy(): void {
		this.sink.unsubscribe();
	}

	writeValue(value: any): void {
		if (Array.isArray(value) && value.length === 0) {
			this.passportSelectForm.patchValue({ type: 'delayedPassports' }, { emitEvent: false });
		} else if (Array.isArray(value)) {
			this.passportSelectForm.patchValue({ type: 'instantPassports' }, { emitEvent: false });
		}
	}

	registerOnChange(fn: any): void {
		this.sink.add(
			this.passportSelectForm.valueChanges
			.pipe(
				map(value => {
					if(value.type === 'delayedPassports') return [];
					return value['passports'];
				})
			)
			.subscribe(fn)
		);
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}


	get type(){
		return this.passportSelectForm.get('type') as FormControl;
	}

	get passports(){
		return this.passportSelectForm.get('passports') as FormArray;
	}

}
