import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlMatchResult, UrlSegment } from '@angular/router';
import { COUNTRY_INFO } from './data/CountryInfo';
import { ORIGIN_INFO } from './data/OriginInfo';
import { RESORT_INFO } from './data/ResortInfo';
import { HotelPageComponent } from './hotel/page/hotel-page/hotel-page.component';
import { HomeComponent } from './index/home/home.component';
import { ReservationPageComponent } from './reservation/page/reservation-page/reservation-page.component';
import { SearchListComponent } from './search-list/components/search-list/search-list.component';
import { SigninComponent } from './status/page/signin/signin.component';
import { StatusComponent } from './status/page/status/status.component';

function destinationsMatcher(segments: UrlSegment[]): UrlMatchResult | null {

	if (segments.length < 2) return null;

	if (!ORIGIN_INFO.hasOwnProperty(segments[0].path)) return null;
	if (!COUNTRY_INFO.hasOwnProperty(segments[1].path)) return null;
	if (segments.length === 3 && !RESORT_INFO.hasOwnProperty(segments[2].path)) return null;

	return {
		consumed: segments,
		posParams: {
			'origin': segments[0],
			'country': segments[1],
			...segments.length === 3 && { 'resort': segments[2] }
		}
	};
}

const routes: Routes = [
	{
		matcher: destinationsMatcher,
		component: SearchListComponent
	}, {
		path: 'hotel/:id',
		component: HotelPageComponent
	}, {
		path: 'reservation',
		component: ReservationPageComponent
	}, {
		path: 'status/:orderNumber/:surname',
		component: StatusComponent
	}, {
		path: 'status',
		component: SigninComponent
	}, {
		path: 'index',
		component: HomeComponent
	}, {
		path: '',
		redirectTo: '/index',
		pathMatch: 'full'
	}/*,
	{
		path: '**',
		component: NotFoundComponent
	}*/
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }