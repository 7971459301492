import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { SearchBarModule } from '../search-bar/search-bar.module';
import { ToursLibModule } from 'tours-lib';



@NgModule({
	declarations: [
		HomeComponent
	],
	imports: [
		CommonModule,
		SearchBarModule,
		ToursLibModule
	]
})
export class IndexModule { }
