import { Component, Input, OnInit } from '@angular/core';
import { InvoiceStatus } from '../../models/InvoiceStatus';

@Component({
	selector: 'tours-invoice',
	templateUrl: './invoice.component.html',
	styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {

	@Input() invoice!: InvoiceStatus;

	constructor() { }

	ngOnInit(): void {
	}

}
