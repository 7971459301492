import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { OfferService } from '../../services/offer.service';

@Component({
	selector: 'tours-reservation-page',
	templateUrl: './reservation-page.component.html',
	styleUrls: ['./reservation-page.component.scss']
})
export class ReservationPageComponent {

	offer$ = this.route.queryParams.pipe(
		filter(params =>
			params.offer
			&& params.hash
			&& params.people
			&& params.tourOperatorId
		),
		map(({ offer, hash, people, tourOperatorId }) => ({ offer, hash, people, tourOperatorId })),
		distinctUntilChanged(),
		switchMap(({ offer, hash, people, tourOperatorId }) =>
			this.offerService.getData(offer, hash, people, tourOperatorId))
	);

	constructor(
		private route: ActivatedRoute,
		private offerService: OfferService
	) {}
}