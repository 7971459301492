import { Resort } from "../models/Locations";
import { COUNTRY } from "./Country";
import { RESORT } from "./Resort";

export const RESORT_INFO: { [key in RESORT]: Resort } = {
	[RESORT.SHARM]: {
		title: `Sharm`,
		id: RESORT.SHARM,
		country: COUNTRY.EGYPT,
		otpuskId: 715
	},
	[RESORT.HURGHADA]: {
		title: `Hurgada`,
		id: RESORT.HURGHADA,
		country: COUNTRY.EGYPT,
		otpuskId: 712
	},
	[RESORT.MARSAALAM]: {
		title: `MARSAALAM`,
		id: RESORT.MARSAALAM,
		country: COUNTRY.EGYPT,
		otpuskId: 1247
	},
	[RESORT.ANTALYA]: {
		title: `ANTALYA`,
		id: RESORT.ANTALYA,
		country: COUNTRY.TURKEY,
		otpuskId: 715
	},
	[RESORT.IZMIR]: {
		title: `Hurgada`,
		id: RESORT.IZMIR,
		country: COUNTRY.TURKEY,
		otpuskId: 712
	},
	[RESORT.ALANYA]: {
		title: `ALANYA`,
		id: RESORT.ALANYA,
		country: COUNTRY.TURKEY,
		otpuskId: 1247
	},
	[RESORT.LARNACA]: {
		title: `LARNACA`,
		id: RESORT.LARNACA,
		country: COUNTRY.CYPRUS,
		otpuskId: 712
	},
	[RESORT.PATHOS]: {
		title: `PATHOS`,
		id: RESORT.PATHOS,
		country: COUNTRY.CYPRUS,
		otpuskId: 1247
	},
}