import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/tours/src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HotelDetails } from '../../models/HotelDetails';

@Injectable({
	providedIn: 'root'
})
export class HotelService {

	detailsURL: string = `${environment.apiURL}/package/hotel`;

	private OTPUSK_IMAGE_PREFIX = 'https://newimg.otpusk.com/3/800x600';
	private OTPUSK_THUMBNAIL_PREFIX = 'https://newimg.otpusk.com/2/400x300/';

	constructor(private httpClient: HttpClient) { }

	public getDetails(hotelId: number): Observable<HotelDetails> {

		return this.httpClient.get<HotelDetails>(`${this.detailsURL}/${hotelId}`, { responseType: "json" })
			.pipe(
				map(this.normilizeImages.bind(this)),
				map(this.generateThumbnails.bind(this)),
			);
	}

	private normilizeImages(details: HotelDetails): HotelDetails {

		details.photos = details.photos
			.map(photo => `${this.OTPUSK_IMAGE_PREFIX}/${photo}`);

		return details;
	}

	private generateThumbnails(details: HotelDetails): HotelDetails {

		details.thumbnailPhotos = details.photos
			.map(photo => !photo.startsWith('http') ? `${this.OTPUSK_THUMBNAIL_PREFIX}/${photo}` : photo);

		return details
	}

}
