import { Component, Inject } from '@angular/core';
import { WINDOW } from '../../injectables/window';

@Component({
	selector: 'app-lang-select',
	templateUrl: './lang-select.component.html',
	styleUrls: ['./lang-select.component.scss']
})
export class LangSelectComponent {

	availableLanguages = [
		{
			name: $localize`:language:English`,
			prefix: 'en',
			iconClass: 'flag-icon-us'
		}, {
			name: $localize`:language:Русский`,
			prefix: 'ru',
			iconClass: 'flag-icon-ru'
		}, {
			name: $localize`:language:Українська`,
			prefix: 'uk',
			iconClass: 'flag-icon-ua'
		}
	]

	constructor(@Inject(WINDOW) private window: Window) {}

	handleLanguageSelect(prefix: string) {

		const pathname = this.window.location.pathname; //Starts with / - Ex. /en/kyiv/marsa-alam
		const search = this.window.location.search; // Ex. ?currentTime=1616498153967&adults=1&&outbound=06-04-2021
		const hash = this.window.location.hash; // Ex. #results

		const newPathname = this.replaceLanguage(pathname, prefix);

		window.location.href = `${newPathname}${search}${hash}`;
	}

	private replaceLanguage(pathname: string, prefix: string): string{
		const noLocationPart = pathname.split('\/')
						.filter(element => element.length > 0)
						.slice(1)
						.join('/');

		if (!!noLocationPart && noLocationPart.length > 0)
			return `/${prefix}/${noLocationPart}`;

		return `/${prefix}`;
	}

}