<div class="status grid-container">
	<div class="grid-x align-center">
		<div class="small-12 medium-6 large-4 cell">
			<form [formGroup]="signInForm" (ngSubmit)="submitOrder()" novalidate>

				<div *ngIf="(error$ | async) === 'true'">
					<div class="callout alert">
						<h4>Произошла ошибка</h4>
						<p>Заказ с такими параметрами не найден</p>
					</div>
				</div>

				<div>
					<h3>Проверка статуса заказа</h3>
					<label>Номер заказа:
						<input type="text" name="Order" formControlName="orderNumber" />
					</label>
					<div *ngIf="orderNumber.invalid && (orderNumber.dirty || orderNumber.touched)"
						class="validation-error">
						<p *ngIf="orderNumber.errors?.required">Номер заказа не введен</p>
						<p *ngIf="orderNumber.errors?.pattern">Номер заказа должен быть числом</p>
					</div>
				</div>

				<div>
					<label>Фамилия одного из туристов (лат) или фамилия покупателя тура (кир):
						<input type="text" formControlName="surname" pattern="[A-Za-zА-Яа-яієїґІЄЇҐʼ\s`'-.,_]+" />
					</label>
					<div *ngIf="surname.invalid && (surname.dirty || surname.touched)" class="validation-error">
						<p *ngIf="surname.errors?.required">Фамилия заполнена неверно</p>
						<p *ngIf="surname.errors?.pattern">Фамилия должна содержать только латинские или
							кириллические символы</p>
					</div>
				</div>

				<div>
					<input type="submit" value="Найти" />
				</div>

			</form>
		</div>
	</div>
</div>