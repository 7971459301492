import { Inject, Pipe, PipeTransform } from '@angular/core';
import { TOUR_OPERATORS } from '../providers/tourOperators';

@Pipe({
	name: 'tourOperators'
})
export class TourOperatorsPipe implements PipeTransform {

	constructor(@Inject(TOUR_OPERATORS) private tourOperators: any) { }

	transform(value: number, ...args: unknown[]): unknown {
		
		if(this.tourOperators.hasOwnProperty(value)){
			return this.tourOperators[value].name;
		}

		return null;
	}

}
