import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { combineLatest, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { Offer } from '../../../models/Offer';
import { PackageContract } from '../../models/PackageContract';
import { CouponService } from '../../services/coupon.service';

@Component({
	selector: 'tours-coupon',
	templateUrl: './coupon.component.html',
	styleUrls: ['./coupon.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: CouponComponent,//forwardRef(() => CustomerInfoComponent),
			multi: true
		}/*, {
			provide: NG_VALIDATORS,
			useExisting: PassportComponent,//forwardRef(() => CustomerInfoComponent),
			multi: true
		}*/
	]
})
export class CouponComponent implements ControlValueAccessor {

	@Input() offer!: Offer;
	@Output() onDiscount: EventEmitter<number> = new EventEmitter();

	couponField = new FormControl("");
	show: boolean = false;

	validCoupon?: String;
	discount?: number;

	loading: boolean = false;

	error?: string;

	onChange = (coupon?: String) => {}
	onTouch = () => {}

	constructor(private couponService: CouponService) { }

	writeValue(obj: any): void {
		this.couponField.setValue(obj);
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouch = fn;
	}

	public toggle() {
		this.show = !this.show;
	}

	public checkCoupon() {

		this.loading = true;
		this.error = undefined;
		this.couponField.disable();

		this.couponService.check(this.couponField.value, this.offer)
			.toPromise()
			.then(value => {
				this.couponField.enable();
				this.loading = false;
				this.error = undefined;

				this.setValidCoupon(this.couponField.value, value)
			}).catch(error => {
				this.couponField.enable();
				this.loading = false;
				this.error = 'Coupon invalid';

				this.removeCoupon();				
			});
	}

	public removeCoupon() {
		this.setValidCoupon(undefined, undefined);
	}

	private setValidCoupon(coupon?: String, discount?: number){
		this.discount = discount;
		this.validCoupon = coupon?.toUpperCase();
		this.onChange(coupon?.toUpperCase());
		this.onDiscount.emit(discount || 0);
	}

}