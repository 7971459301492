<footer class="site-footer">
	<div class="grid-container">
		<div class="grid-x grid-margin-x grid-margin-y">
			<div class="cell small-12 medium-7 text-justify">
				<div class="footertext">
					<p i18n="@@fooer-company-description">
						Tours.com.ua is a service for purchasing tours to Egypt, Turkey,
						Zanzibar, Dominican Republic and other destinations. On the 
						site you will find offers from the largest and the most reliable 
						tour operators in Ukraine, which you can book without
						leaving your home.
					</p>
				</div>
				<div class="company-details">
					<p i18n="@@fooer-company-details">
						LLC "CLEVER TRAVEL" (EGRPOY code – 41625958).
						The bank guarantee of financial security of the travel 
						agent's civil liability was issued by PJSC "BANK INVESTITSІY TA
						ZAOSCHADZHEN” №21225/18-Г until October 30, 2023.
					</p>
				</div>
				<div>
					<small i18n="@@footer-payment-info">
						All payments are made in UAH. Conversion is made by the 
						commercial exchange rate of the respective tour operator.
					</small>
				</div>
			</div>
			<div class="cell small-12 medium-5 links-block">
				<div class="grid-x grid-margin-x grid-margin-y">
					<div class="cell small-12 medium-8 help-links">
						<h5>Помощь</h5>
						<ul class="footer-menu">
							<li>
								<a href="https://tours.com.ua/status">Статус заказа</a>
							</li>
							<li>
								<a href="https://tours.com.ua/tour-operators">Курсы валют</a>
							</li>
							<li>
								<a href="https://tours.com.ua/security-guarantee/" target="_self">Безпека та гарантії</a>
							</li>
							<li>
								<a href="https://tours.com.ua/faq/" target="_self">Вопросы и ответы</a>
							</li>
							<li>
								<a href="https://tours.com.ua/airline-rules-baggage/" target="_self">Правила перевезення
									багажу</a>
							</li>
							<li>
								<a href="https://tours.com.ua/airline-rules-baggage/" target="_self">Перетин кордонів під час
									пандемії COVID</a>
							</li>
						</ul>
					</div>
					<div class="cell small-12 medium-4">
						<h5>О компании</h5>
						<ul class="footer-menu">
							<li>
								<a href="https://clevertravel.com.ua/terms-of-service/">Політика конфіденційності</a>
							</li>
							<li>
								<a href="https://tours.com.ua/documents/" target="_self">Наши документы</a>
							</li>
							<li>
								<a href="https://clevertravel.com.ua/o-nas/">О нас</a>
							</li>
							<li>
								<a href="https://clevertravel.com.ua/contacts/">Контакты</a>
							</li>
						</ul>
					</div>
				</div>
				<div class="grid-x grid-margin-x">
					<div class="cell small-12 medium-8">
						<app-lang-select></app-lang-select>
						<app-currency-select></app-currency-select>
					</div>
					<div class="cell small-12 medium-4">
						<img src="https://tours.com.ua/images/logo-liqpay-white-color.png" alt="liqpay logo" class="liqpay-logo" />
					</div>
				</div>
			</div>
		</div>

		<div class="grid-x grid-margin-x copyright-block">
			<div class="cell auto">
				<p>tours.com.ua © 2021</p>
			</div>
		</div>
	</div>
</footer>