<div class="photos-block">
	<div class="photos-wrap" photo-swipe [photos]="photos">

		<div class="main-photo image" [style.background-image]="'url(' + mainPhoto + ')'"
			data-index="0"></div>

		<div class="small-photos" *ngIf="thumbnails.length > 1">
			<div class="image" *ngFor="let thumb of thumbnails | slice:1:7; let i = index"
				[style.background-image]="'url(' + thumb + ')'" [attr.data-index]="i + 1">
				<div class="more-photos">+ {{ thumbnails.length }} фото</div>
			</div>
		</div>

	</div>
</div>