import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchListComponent } from './components/search-list/search-list.component';
import { SearchBarModule } from '../search-bar/search-bar.module';
import { ToursLibModule } from 'tours-lib';
import { HttpClientModule } from '@angular/common/http';
import { HotelListItemComponent } from './components/hotel-list-item/hotel-list-item.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';


@NgModule({
	declarations: [
		SearchListComponent,
		HotelListItemComponent
	],
	imports: [
		CommonModule,
		SearchBarModule,
		ToursLibModule,
		HttpClientModule,
		SharedModule,
		RouterModule,
		MatDialogModule
	]
})
export class SearchListModule { }
