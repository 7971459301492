import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReservationPageComponent } from './page/reservation-page/reservation-page.component';
import { TourDetailsComponent } from './components/tour-details/tour-details.component';
import { SurchargesComponent } from './components/surcharges/surcharges.component';
import { PaymentOptionsComponent } from './components/payment-options/payment-options.component';
import { HttpClientModule } from '@angular/common/http';
import { ToursLibModule } from 'tours-lib';
import { NotesComponent } from './components/notes/notes.component';
import { CouponComponent } from './components/coupon/coupon.component';
import { FlightSelectComponent } from './components/flight-select/flight-select.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ReservationComponent } from './components/reservation/reservation.component';
import { MatDialogModule } from '@angular/material/dialog';
import { PaymentService } from './services/payment.service';
import { OfferService } from './services/offer.service';
import { CouponService } from './services/coupon.service';
import { SharedModule } from '../shared/shared.module';



@NgModule({
	declarations: [
		ReservationPageComponent,
		TourDetailsComponent,
		SurchargesComponent,
		PaymentOptionsComponent,
		NotesComponent,
		CouponComponent,
		FlightSelectComponent,
		ReservationComponent
	],
	imports: [
		CommonModule,
		HttpClientModule,
		ToursLibModule,
		ReactiveFormsModule,
		MatDialogModule,
		SharedModule
	],
	providers: [
		PaymentService, OfferService, CouponService
	]
})
export class ReservationModule { }
