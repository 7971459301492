import { Component } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';

@Component({
	selector: 'tours-signin',
	templateUrl: './signin.component.html',
	styleUrls: ['./signin.component.scss']
})
export class SigninComponent {

	readonly signInForm = this.fb.group({
		orderNumber: [null, [Validators.required, Validators.pattern("[0-9]+")]],
		surname: [null, [Validators.required, Validators.pattern("[A-Za-zА-Яа-яієїґІЄЇҐʼ\s`'-.,_]+")]]
	});

	readonly error$ = this.route.queryParams.pipe(
		map(({ error }) => error)
	);

	constructor(
		private router: Router,
		private fb: FormBuilder,
		private route: ActivatedRoute
	) {}

	public submitOrder() {
		if(this.signInForm.valid){
			this.router.navigate(['status', this.orderNumber.value, this.surname.value]);
		} else {
			this.signInForm.markAllAsTouched();
		}
		
	}

	get orderNumber() {
		return this.signInForm.get('orderNumber') as FormControl;
	}

	get surname() {
		return this.signInForm.get('surname') as FormControl;
	}

}
