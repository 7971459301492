import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FlightOptions } from '../../../models/Flight';
import { OfferId } from '../../../models/SearchOffer';
import { HotelResult } from '../../../models/SearchResult';

@Component({
	selector: 'tours-hotel-list-item',
	templateUrl: './hotel-list-item.component.html',
	styleUrls: ['./hotel-list-item.component.scss']
})
export class HotelListItemComponent {

	@Input() hotel!: HotelResult;
	@Output() onOfferSelect: EventEmitter<OfferId> = new EventEmitter();
	@Output() onFlightSelect: EventEmitter<FlightOptions> = new EventEmitter();

	constructor() { }

	public goToReservation() {
		this.onOfferSelect.emit({
			id: this.hotel.cheapestOffer.id,
			operatorId: this.hotel.cheapestOffer.operatorId
		});
	}

	get routerLink() {
		return ['/hotel', this.hotel.id]
	}

	get additionalQueryParams(){
		return { origin: 'kyiv' }
	}

	public showFlightTimetable(flights: FlightOptions) {
		this.onFlightSelect.emit(flights);
	}
}