import { Component, Input } from '@angular/core';
import { HotelDetails } from '../../../models/HotelDetails';

@Component({
	selector: 'tours-hotel',
	templateUrl: './hotel.component.html',
	styleUrls: ['./hotel.component.scss']
})
export class HotelComponent {

	@Input() hotel!: HotelDetails;

	constructor() { }
	
}
