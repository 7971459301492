import { OverlayRef, ConnectedPosition, Overlay, PositionStrategy } from "@angular/cdk/overlay";
import { TemplatePortal } from "@angular/cdk/portal";
import { Component, AfterViewInit, OnDestroy, ContentChild, ElementRef, TemplateRef, ViewContainerRef, HostListener, Inject } from "@angular/core";
import { fromEvent } from "rxjs";
import { WINDOW } from "../../injectables/window";
import { UnsubscribeOnDestroy } from "../../models/UnsubscribeOnDestroy";

@Component({
	selector: 'app-popover',
	templateUrl: './popover.component.html',
	styleUrls: ['./popover.component.scss']
})
export class PopoverComponent extends UnsubscribeOnDestroy implements AfterViewInit, OnDestroy {

	@ContentChild('header') headerRef!: ElementRef;
	@ContentChild('content') contentRef!: TemplateRef<unknown>;
	private templatePortal!: TemplatePortal<any>;
	private overlayRef!: OverlayRef;

	private connectedPosition: ConnectedPosition[] = [
		{
			originX: 'start',
			originY: 'bottom',
			overlayX: 'start',
			overlayY: 'top',
		}, {
			originX: 'start',
			originY: 'top',
			overlayX: 'start',
			overlayY: 'bottom'
		}
	];

	private MAX_MOBILE_SCREEN_WIDTH = 640;

	constructor(
		private overlay: Overlay,
		private viewContainerRef: ViewContainerRef,
		@Inject(WINDOW) private window: Window
	) { super(); }


	ngAfterViewInit(): void {

		this.templatePortal = new TemplatePortal(this.contentRef, this.viewContainerRef, {
			close: () => {
				this.overlayRef.detach();
			}
		});

		this.overlayRef = this.overlay.create({
			hasBackdrop: true,
			positionStrategy: this.selectPositionStrategy(),
			scrollStrategy: this.overlay.scrollStrategies.block()
		});

		this.sink.add(this.overlayRef.backdropClick().subscribe(() => {
			this.overlayRef.detach();
		}));

		this.sink.add(
			fromEvent(this.headerRef.nativeElement, 'click')
				.subscribe((event) => this.overlayRef.attach(this.templatePortal))
		);

	}

	@HostListener('window:resize')
	public onResize(): void {
		this.overlayRef.updatePositionStrategy(this.selectPositionStrategy());
	}

	private selectPositionStrategy(): PositionStrategy {

		const width = this.window.innerWidth;
		if (width > this.MAX_MOBILE_SCREEN_WIDTH) {
			return this.overlay
				.position()
				.flexibleConnectedTo(this.headerRef)
				.withPositions(this.connectedPosition);
		}
		return this.overlay
			.position()
			.global()
			.centerHorizontally()
			.centerVertically();
	}
}
