import { Component, Input } from '@angular/core';

@Component({
	selector: 'tours-photos-line',
	templateUrl: './photos-line.component.html',
	styleUrls: ['./photos-line.component.scss']
})
export class PhotosLineComponent {

	@Input() photos!: string[];
	@Input() thumbnails!: string[];

	constructor() { }

}
