import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/tours/src/environments/environment';
import { Observable } from 'rxjs';
import { PaymentMethod } from '../../models/PaymentMethod';
import { PackageContract } from '../models/PackageContract';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';

dayjs.extend(utc)

@Injectable()
export class PaymentService {

	paymentURL: string = `${environment.orderURL}/package_checkout/payment_methods`;

	constructor(private httpClient: HttpClient) { }

	public getPaymentMethods(offer: PackageContract): Observable<PaymentMethod[]>{
		return this.httpClient.post<PaymentMethod[]>(this.paymentURL, this.fallbackOrder(offer));
	}

	private fallbackOrder(offer: PackageContract): PackageContract {
		// Trying to fallback to date property if no flight details were provided
		if (!offer.hasOwnProperty('departureDateTime') && offer.hasOwnProperty('date')) {
			//@ts-ignore
			offer['departureDateTime'] = offer['date'];
		}
		// Trying to fallback to date property if no flight details were provided
		if (!offer.hasOwnProperty('returnDateTime') && offer.hasOwnProperty('date') && offer.hasOwnProperty('duration')) {
			//@ts-ignore
			offer['returnDateTime'] = dayjs.utc(offer['date']).add(offer['duration'] - 1, 'days').toISOString();
		}

		return offer;
	}
}
