import { Component, Input } from '@angular/core';
import { map } from 'rxjs/operators';
import { PriceMap } from '../../models/PriceMap';
import { CurrentCurrencyService } from '../../services/current-currency.service';

@Component({
	selector: 'app-price',
	templateUrl: './price.component.html',
	styleUrls: ['./price.component.scss']
})
export class PriceComponent {
	
	@Input() price!: PriceMap;

	public readonly currentPrice$ = this.currencyService.get().pipe(
		map((currency) => this.price[currency])
	);

	constructor(private currencyService: CurrentCurrencyService) { }

}
