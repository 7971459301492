<ng-container *ngIf="(currentPrice$ | async) as price">
	<div class="price-block" [class.discounted]="!!price.oldPrice">
		<div class="best-deal">
			<i class="fas fa-fire-alt"></i> Горящая цена!
		</div>
		<div>
			<span class="old-price" *ngIf="!!price.oldPrice">
				<lib-price-amount [amount]="price.oldPrice" [currency]="price.currency"></lib-price-amount>
			</span>
			<span [class.best-deal-price]="!!price.oldPrice" class="perperson-price">
				<lib-price-amount [amount]="price.pricePerPerson" [currency]="price.currency"></lib-price-amount>
				<span class="per-person">/чел</span>
			</span>
		</div>
		<div class="overall">
			Всего <lib-price-amount [amount]="price.price" [currency]="price.currency"></lib-price-amount>
		</div>
	</div>
</ng-container>