import { Component, EventEmitter, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ORIGIN_INFO } from '../../../data/OriginInfo';
import { Origin } from '../../../models/Locations';

@Component({
	selector: 'tours-origin-select',
	templateUrl: './origin-select.component.html',
	styleUrls: ['./origin-select.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: OriginSelectComponent,//forwardRef(() => CustomerInfoComponent),
			multi: true
		}
	]
})
export class OriginSelectComponent implements ControlValueAccessor {

	@Output() close = new EventEmitter();

	originList: Origin[] = Object.values(ORIGIN_INFO);
	active!: Origin;

	onTouchedCallback: () => void = () => { };
	onChangedCallback: (value: Origin) => void = () => { };

	constructor() { }
	
	writeValue(obj: Origin): void {
		this.active = obj;
	}

	registerOnChange(fn: any): void {
		this.onChangedCallback = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}

	selectOrigin(origin: Origin){
		this.onChangedCallback(origin);
		this.close.emit();
	}

}
