import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { RESORT } from '../../../data/Resort';
import { Country, Resort } from '../../../models/Locations';
import { DESTINATIONS, DESTINATION_PROVIDER, RESORTS } from '../providers/destinationProvider';

@Component({
	selector: 'tours-resort-list-select',
	templateUrl: './resort-list-select.component.html',
	styleUrls: ['./resort-list-select.component.scss'],
	providers: [DESTINATION_PROVIDER]
})
export class ResortListSelectComponent {

	@Output() onSelect = new EventEmitter<Country | Resort>();

	resortList: Resort[] = [];
	selectedCountry: Country | undefined;

	constructor(
		@Inject(DESTINATIONS) public countriesList: Country[],
		@Inject(RESORTS) private resorts: { [key in RESORT]: Resort }
	) { }

	public handleCountrySelected(country: Country) {
		this.resortList = country.resorts.map(resortId => this.resorts[resortId]);
		this.selectedCountry = country;
	}

	public handleResortSelected(resort?: Resort) {
		this.onSelect.emit(resort || this.selectedCountry);
	}

	public handleBackButton() {
		this.resortList = [];
		this.selectedCountry = undefined;
	}
}
