<div class="duration-select">
	<div class="label">
		<h4 class="show-for-small-only">Выбор длительности тура</h4>
		<div>From {{durationFrom}} to {{durationTo}} days</div>
		<small>From {{durationFrom - 1}} to {{durationTo - 1}} nights</small>
	</div>
	<div class="days">
		<div
			class="day"
			*ngFor="let day of days"
			[ngClass]="{'limit': day === durationFrom || day === durationTo, 'middle': durationFrom < day && day < durationTo }"
			(click)="durationSelected(day)"
		>
			{{day}}
		</div>
	</div>
</div>