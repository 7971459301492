<div class="grid-container">
	<ng-container *ngIf="(offer$ | async) as data; else loading">
		<tours-reservation [data]="data"></tours-reservation>
	</ng-container>

	<ng-template #loading>
		<div class="align-self-middle align-self-center text-center">
			<app-loading-circles></app-loading-circles>
			<h3>Fetching tour details</h3>
			<p>It may take up to 30 sec</p>
		</div>
	</ng-template>
</div>