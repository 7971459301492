import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ToursLibModule } from 'tours-lib';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HotelModule } from './hotel/hotel.module';
import { IndexModule } from './index/index.module';
import { ReservationModule } from './reservation/reservation.module';
import { SearchBarModule } from './search-bar/search-bar.module';
import { SearchListModule } from './search-list/search-list.module';
import { StatusModule } from './status/status.module';

@NgModule({
	declarations: [
		AppComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		ToursLibModule,
		IndexModule,
		SearchBarModule,
		SearchListModule,
		HotelModule,
		ReservationModule,
		StatusModule,
		NoopAnimationsModule
	],
	providers: [],
	bootstrap: [AppComponent]
})
export class AppModule { }
