import { Component, Input } from '@angular/core';
import { map } from 'rxjs/operators';
import { PriceMap } from '../../models/PriceMap';
import { CurrentCurrencyService } from '../../services/current-currency.service';

@Component({
  selector: 'lib-basic-price',
  templateUrl: './basic-price.component.html',
  styleUrls: ['./basic-price.component.scss']
})
export class BasicPriceComponent {

	@Input() price!: PriceMap;

	readonly currentPrice$ = this.currencyService.get().pipe(
		map((currency) => this.price[currency]),
	);

	constructor(private currencyService: CurrentCurrencyService) {}
}
