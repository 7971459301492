<div class="header">
	<div class="grid-container">
		<div class="grid-x show-for-medium">
			<div class="medium-5 large-4 cell">
				<a href="//charter.tours.com.ua">
					<img src="https://tours.com.ua/images/logo_tours_com_ua_color.png" alt="tours logo" class="color-logo"/>
					<img src="https://tours.com.ua/images/logo_tours_com_ua.png" alt="tours logo"  class="white-logo"/>
				</a>
			</div>
			<div class="medium-7 large-8 cell clever-nav">
				<div class="text-right clever-phone">Нам можно
					<span class="clever-phone-link phone-icon" (click)="openContactsDialog()">позвонить</span> или
				</div>
				<div class="text-right clever-phone">написать
					<a href="mailto:clevertravel.com.ua@gmail.com" class="email-icon clever-phone-link">email</a> /
					<a href="https://www.facebook.com/clevertravel.com.ua/"
						class="facebook-icon clever-phone-link">facebook</a>
				</div>
				<div class="text-right clever-phone-info">
					с 11:00 до 18:00 в будние дни
				</div>
			</div>
		</div>

		<div class="show-for-medium main-navigation">
			<ul class="inline-list">
				<li class="menu-item" *ngFor="let link of links">
					<a [href]="link.url">{{ link.text }}</a>
				</li>
			</ul>
		</div>
	</div>

	<div class="show-for-small-only">

		<div class="title-bar" [ngClass]="{'menu-open': menuVisible }">
			<div class="left-button" (click)="menuVisible = !menuVisible">
				<button class="menu-icon" type="button" *ngIf="!menuVisible"></button>
				<button class="close-button" type="button" *ngIf="menuVisible"></button>
			</div>
			<div class="title-text">
				Меню
			</div>
		</div>

		<div class="menu-small" *ngIf="menuVisible">
			<ul class="text-center">
				<li class="menu-item" *ngFor="let link of links">
					<a href="link.url"><div>{{ link.text }}</div></a>
				</li>
			</ul>
		</div>

		<div class="small-header">
			<a href="//charter.tours.com.ua">
				<img src="https://tours.com.ua/images/logo_tours_com_ua_color.png" alt="tours logo" class="color-logo" />
				<img src="https://tours.com.ua/images/logo_tours_com_ua.png" alt="tours logo" class="white-logo" />
			</a>
		</div>
	</div>
</div>