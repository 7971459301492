<div class="grid-x grid-padding-x">
	<div class="small-12 cell">
		<h1>Бронированние тура</h1>
	</div>
</div>
<form [formGroup]="orderForm" (ngSubmit)="submitOrder()">
	<div class="grid-x grid-padding-x">
		<div class="small-12 medium-8 cell reservation-content">

			<div class="grid-x grid-padding-x">
				<div class="small-12 cell">
					<div class="photo show-for-small-only">
						<img [src]="data.hotel.photos[0]" />
					</div>
					<h2>{{ data.hotel.name }}</h2>
				</div>
				<div class="small-6 medium-4 cell">
					<div class="label">Туроператор</div>
					{{ data.offer.operatorId | tourOperators }}
				</div>
				<div class="small-6 medium-4 cell">
					<div class="label">Номер предложения</div>
					{{ data.offer.id }}
				</div>
				<div class="small-6 medium-4 cell">
					<div class="label">Питание</div>
					{{ data.offer.food | food }}
				</div>
				<div class="small-6 medium-4 cell">
					<div class="label">Номер</div>
					{{ data.offer.room }}
				</div>
				<div class="small-6 medium-4 cell">
					<div class="label">Кількість днів включаючи перельоти:</div>
					{{ data.offer.duration }}
				</div>

			</div>

			<div *dev>
				{{ orderForm.value | json }}
			</div>

			<div class="grid-x grid-padding-x">
				<div class="cell small-12 medium-6">
					<h4>Перелет туда</h4>
					<tours-flight-select [options]="data.offer.flightOptions.outboundFlights"
						formControlName="outboundFlight"></tours-flight-select>
				</div>
				<div class="cell small-12 medium-6">
					<h4>Перелет обратно</h4>
					<tours-flight-select [options]="data.offer.flightOptions.returnFlights"
						formControlName="returnFlight"></tours-flight-select>
				</div>
			</div>

			<div>
				<app-customer-info formControlName="customerInfo"></app-customer-info>
			</div>

			<div>
				<app-passport-select formControlName="passports" [adults]="data.offer.adults"
					[children]="data.offer.children"></app-passport-select>
			</div>

			<div>
				<tours-surcharges [surcharges]="data.offer.surcharges" formControlName="surcharges"></tours-surcharges>
			</div>

			<div>
				<tours-notes [notes]="data.offer.notes"></tours-notes>
			</div>

			<div>
				<tours-payment-options [contract$]="packageContract$" formControlName="payment"></tours-payment-options>
			</div>

		</div>
		<div class="small-12 medium-4 cell">

			<div class="show-for-medium">
				<tours-tour-details [data]="data"></tours-tour-details>
			</div>

			<tours-coupon [offer]="data.offer" formControlName="coupon" (onDiscount)="setDiscount($event)"></tours-coupon>
			
			<div *ngIf="orderForm.get('payment')?.value || false">
				Полная стоимость: {{ orderForm.get('payment')?.value.price }} грн.
			</div>

			<div *ngIf="orderForm.get('payment')?.value || false">
				К оплате: {{ orderForm.get('payment')?.value.minPrepay - discountAmount}} грн.
			</div>

			<input type="submit" value="Бронировать" class="submit-button" />

			<div *ngIf="passports.invalid && (passports.dirty || passports.touched)" class="validation-error">
				<p i18n="passports invalid">Passports are invalid</p>
			</div>

			<div *ngIf="customerInfo.invalid && (customerInfo.dirty || customerInfo.touched)" class="validation-error">
				<p i18n="customerInfo invalid">Customer info is invalid</p>
			</div>
		</div>
	</div>
</form>