import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/tours/src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { People } from 'tours-lib';
import { COUNTRY_INFO } from '../../data/CountryInfo';
import { HotelSearchResult } from '../../models/HotelSearchResult';
import { Resort } from '../../models/Locations';
import { SearchRequest } from '../../models/SearchRequest';
import { SearchResult } from '../../models/SearchResult';
import { SearchState } from '../../models/SearchState';


@Injectable({
	providedIn: 'root'
})
export class SearchService {

	initSearchURL: string = `${environment.apiURL}/package/search`;
	getSearchResultsURL: string = `${environment.apiURL}/package/result`;
	getSearchResultsForHotelURL: string = `${environment.apiURL}/package/search_single`;
	SESSION_HEADERS_NAME: string = 'X-Session';

	TOURS_IMAGE_PREFIX: string = `${environment.staticURL}/`;
	OTPUSK_IMAGE_PREFIX: string = 'https://newimg.otpusk.com/2/400x300/';
	HOTEL_IMAGE_STUB: string = '/images/hotel_stub.jpg';

	constructor(public httpClient: HttpClient) { }

	public search(params: SearchRequest): Observable<string> {

		return this.httpClient.get(this.initSearchURL, {
			params: this.convertSearchRequestToHttpParams(params),
			observe: "response",
			responseType: "text"
		})
			.pipe(
				map(httpResponse => {
					if (httpResponse.headers.has(this.SESSION_HEADERS_NAME))
						return httpResponse.headers.get(this.SESSION_HEADERS_NAME) as string;
					throw Error('No correct headers found');
				})
			);
	}

	public queryResult(state: SearchState): Observable<SearchResult> {

		const httpParams = new HttpParams()
			.append('session', state.hash)
			.append('offset', state.offset.toString());

		return this.httpClient.get<SearchResult>(this.getSearchResultsURL, {
			params: httpParams,
			responseType: "json"
		}).pipe(
			map(this.normilizeImages.bind(this))
		)
	}

	public queryResultForHotel(state: SearchState): Observable<HotelSearchResult> {

		const httpParams = new HttpParams()
			.append('session', state.hash)
			.append('offset', state.offset.toString());

		return this.httpClient
			.get<HotelSearchResult>(
				this.getSearchResultsForHotelURL,
				{
					params: httpParams,
					responseType: "json"
				}
			);
	}

	public loadMore(state: SearchState): Observable<any> {

		const httpParams = new HttpParams()
			.append('hash', state.hash) //TODO: should be renamed to session on backend and frontend
			.append('offset', state.offset.toString());

		return this.httpClient.get(this.initSearchURL, {
			params: httpParams,
			observe: "response",
			responseType: "text"
		})
	}

	private convertSearchRequestToHttpParams(searchRequest: SearchRequest): HttpParams {

		const hotelIds = (searchRequest.destination.id === 'hotel') ? searchRequest.destination.otpuskId : undefined;

		const country = searchRequest.destination.hasOwnProperty('country')
			? COUNTRY_INFO[(searchRequest.destination as Resort).country]
			: searchRequest.destination;

		const countryId = country.otpuskId.toString();
		const resortIds = searchRequest.destination.hasOwnProperty('country')
			? (searchRequest.destination as Resort).otpuskId.toString()
			: undefined;

		let httpParams = new HttpParams()
			.append('checkIn', searchRequest.dateRange.checkIn)
			.append('checkInTo', searchRequest.dateRange.checkInTo)
			.append('durationFrom', searchRequest.duration.durationFrom.toString())
			.append('durationTo', searchRequest.duration.durationTo.toString())
			.append('origin', searchRequest.origin.otpuskId.toString())
			.append('people', People.toOtpuskFormat(searchRequest.people));

		if (countryId)
			httpParams = httpParams.append('countryId', countryId);

		if (resortIds)
			httpParams = httpParams.append('resortIds', resortIds);

		if(hotelIds)
			httpParams = httpParams.append('hotelIds', `${hotelIds}`);

		return httpParams;
	}

	private normilizeImages(searchResult: SearchResult ): SearchResult {

		for(let hotel of searchResult.hotels){
			if (hotel.photo == null){
				hotel.photo = this.HOTEL_IMAGE_STUB;
			} else if (hotel.photo.indexOf('/') === 4){
				hotel.photo = `${this.TOURS_IMAGE_PREFIX}${hotel.photo}`;
			} else {
				hotel.photo = `${this.OTPUSK_IMAGE_PREFIX}${hotel.photo}`;
			}
		}

		return searchResult;
	}

}
