import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingOverlay, StatusComponent } from './page/status/status.component';
import { HttpClientModule } from '@angular/common/http';
import { InvoiceComponent } from './components/invoice/invoice.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { ToursLibModule } from 'tours-lib';
import { StatusService } from './services/status.service';
import { SigninComponent } from './page/signin/signin.component';
import { ReactiveFormsModule } from '@angular/forms';



@NgModule({
	declarations: [
		StatusComponent,
		InvoiceComponent,
		SigninComponent
	],
	imports: [
		CommonModule,
		HttpClientModule,
		OverlayModule,
		ToursLibModule,
		ReactiveFormsModule
	],
	providers: [StatusService],
	entryComponents: [
		LoadingOverlay
	]
})
export class StatusModule { }
