import { Component, OnDestroy } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from '@angular/forms';
import { SubSink } from 'subsink';

@Component({
	selector: 'app-customer-info',
	templateUrl: './customer-info.component.html',
	styleUrls: ['./customer-info.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: CustomerInfoComponent,//forwardRef(() => CustomerInfoComponent),
			multi: true
		}, {
			provide: NG_VALIDATORS,
			useExisting: CustomerInfoComponent,//forwardRef(() => CustomerInfoComponent),
			multi: true
		}
	]
})
export class CustomerInfoComponent implements ControlValueAccessor, Validator, OnDestroy {

	onTouched: () => void = () => { };
	customerInfoForm: FormGroup;
	sink: SubSink = new SubSink();

	constructor(private fb: FormBuilder/*, @Self() public controlDir: NgControl*/) {

		this.customerInfoForm = this.fb.group({
			name: [null, [Validators.required, Validators.minLength(2)]],
			surname: [null, [Validators.required, Validators.minLength(2)]],
			phone: [null, [Validators.required]],
			email: [null, [Validators.required, Validators.email]]
		});

	}
	ngOnDestroy(): void {
		this.sink.unsubscribe();
	}

	validate(control: AbstractControl): ValidationErrors | null {
		return this.customerInfoForm.valid
				? null
				: { invalidForm: { valid: false, message: "customerInfo fields are invalid" } };
	}

	writeValue(value: any): void {
		value && this.customerInfoForm.setValue(value, {emitEvent: false});
	}

	registerOnChange(fn: any): void {
		this.sink.add(this.customerInfoForm.valueChanges.subscribe(fn));
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		isDisabled	? this.customerInfoForm.disable()
					: this.customerInfoForm.enable();
	}

	get name(){
		return this.customerInfoForm.get('name') as FormControl;
	}

	get surname() {
		return this.customerInfoForm.get('surname') as FormControl;
	}

	get phone(){
		return this.customerInfoForm.get('phone') as FormControl;
	}

	get email() {
		return this.customerInfoForm.get('email') as FormControl;
	}
}