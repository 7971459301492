import { Component, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { filter } from 'rxjs/operators';
import { People } from '../../models/people.model';

@Component({
	selector: 'app-people-select',
	templateUrl: './people-select.component.html',
	styleUrls: ['./people-select.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: PeopleSelectComponent,
			multi: true
		}, {
			provide: NG_VALIDATORS,
			useExisting: PeopleSelectComponent,
			multi: true
		}
	]
})
export class PeopleSelectComponent implements OnInit, ControlValueAccessor, Validator {

	adultsArray = [1, 2, 3, 4];

	adults: number = 2;
	children: number[] = [];

	valueStr: string = '';

	childAgeSelect: FormControl;

	onTouched: () => void = () => { };
	onChange: (value: People) => void = () => { };

	childrenAges = [
		{ value: null, text: $localize`Добавить ребенка` },
		{ value: 1, text: 'до 2х лет' },
		{ value: 2, text: '2 года' },
		{ value: 3, text: '3 года' },
		{ value: 4, text: '4 года' },
		{ value: 5, text: '5 лет' },
		{ value: 6, text: '6 лет' },
		{ value: 7, text: '7 лет' },
		{ value: 8, text: '8 лет' },
		{ value: 9, text: '9 лет' },
		{ value: 10, text: '10 лет' },
		{ value: 11, text: '11 лет' },
		{ value: 12, text: '12 лет' },
		{ value: 13, text: '13 лет' },
		{ value: 14, text: '14 лет' },
		{ value: 15, text: '15 лет' },
		{ value: 16, text: '16 лет' },
		{ value: 17, text: '17 лет' }
	];

	constructor() {
		this.childAgeSelect = new FormControl(null);

		this.childAgeSelect.valueChanges
			.pipe(
				filter( value => !!value)
			)
			.subscribe(value => {
				this.children = [...this.children, +value];
				this.update();
				this.childAgeSelect.setValue(null, {emitEvent: false});
			})
	}


	writeValue(obj: People): void {
		this.adults = obj.adults;
		this.children = [...obj.children];
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	ngOnInit(): void {
	}

	setAdults(numAdults: number){
		this.adults = numAdults;
		this.update();
	}

	removeChild(index: number){
		this.children.splice(index, 1);
		this.update();
	}

	update(){
		this.onChange({
			adults: this.adults,
			children: this.children
		});
	}

	validate(control: AbstractControl): ValidationErrors | null {
		return null;
	}
}