import { Component } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface Duration {
	durationFrom: number;
	durationTo: number;
}

@Component({
	selector: 'tours-duration-select',
	templateUrl: './duration-select.component.html',
	styleUrls: ['./duration-select.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: DurationSelectComponent,//forwardRef(() => CustomerInfoComponent),
			multi: true
		}
	]
})
export class DurationSelectComponent implements ControlValueAccessor {

	MIN_DURATION = 3;
	MAX_DURATION = 15;

	durationFrom: number = 8;
	durationTo: number = 12;

	days: number[];

	onTouchedCallback: () => void = () => { };
	onChangedCallback: (value: Duration) => void = () => { };

	constructor() {
		this.days = Array(this.MAX_DURATION - this.MIN_DURATION)
				.fill(0).map((value, index) => (this.MIN_DURATION + index));
	}

	public durationSelected(value: number){

		if (this.durationFrom !== this.durationTo){
			this.durationFrom = value;
			this.durationTo = value;
		} else {
			this.durationTo = value;
		}

		if(this.durationFrom > this.durationTo){
			[this.durationFrom, this.durationTo] = [this.durationTo, this.durationFrom];
		}

		this.onChangedCallback({
			durationFrom: this.durationFrom,
			durationTo: this.durationTo
		});
	}

	writeValue(obj: Duration): void {
		this.durationTo = obj.durationTo;
		this.durationFrom = obj.durationFrom;
	}

	registerOnChange(fn: any): void {
		this.onChangedCallback = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}

}