import { Country } from "../models/Locations";
import { COUNTRY } from "./Country";
import { RESORT } from "./Resort";

export const COUNTRY_INFO: { [key in COUNTRY]: Country } = {
	[COUNTRY.EGYPT]: {
		title: `Egypt`,
		id: COUNTRY.EGYPT,
		resorts: [RESORT.SHARM, RESORT.MARSAALAM, RESORT.HURGHADA],
		code: 'eg',
		otpuskId: 43
	},
	[COUNTRY.TURKEY]: {
		title: `Turkey`,
		id: COUNTRY.TURKEY,
		resorts: [RESORT.ALANYA, RESORT.ANTALYA, RESORT.IZMIR],
		code: 'tr',
		otpuskId: 115
	},
	[COUNTRY.CYPRUS]: {
		title: `Cyprus`,
		id: COUNTRY.CYPRUS,
		resorts: [RESORT.LARNACA, RESORT.PATHOS],
		code: 'cy',
		otpuskId: 54
	}
}