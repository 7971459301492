import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/tours/src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Offer } from '../../models/Offer';

@Injectable()
export class CouponService {

	checkURL: string = `${environment.orderURL}/coupon/check`;

	constructor(public httpClient: HttpClient) { }

	public check(coupon: string, contract: Offer): Observable<number> {

		const params = new HttpParams()
			.append("coupon", coupon?.toUpperCase())
			.append("price", `${contract.extendedPrice['UAH'].price}`);

		const headers = new HttpHeaders({
			'Content-Type': 'application/x-www-form-urlencoded'
		});

		return this.httpClient.post<{discount: number}>(this.checkURL, params.toString(), { headers })
			.pipe(
				map(value => value.discount),
				map(value => Math.floor(value))
			);
	}
}
