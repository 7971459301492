import { InjectionToken, Provider } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { map, shareReplay, switchMap } from "rxjs/operators";
import { HotelDetails } from "../../../models/HotelDetails";
import { Location } from "../../../models/Locations";
import { HotelService } from "../../../shared/services/hotel.service";

export const HOTEL_DETAILS = new InjectionToken<Observable<HotelDetails>>(
	'A stream with filtered search results'
);

export const HOTEL_LOCATION = new InjectionToken<Observable<Location>>(
	'A stream with hotel location'
);

export const HOTEL_PROVIDER: Provider[] = [
	{
		provide: HOTEL_DETAILS,
		deps: [ActivatedRoute, HotelService],
		useFactory: hotelDetailsFactory
	},
	{
		provide: HOTEL_LOCATION,
		deps: [HOTEL_DETAILS],
		useFactory: hotelLocationFactory
	}
];

function hotelDetailsFactory(route: ActivatedRoute, hotelService: HotelService) {
	return route.params.pipe(
		switchMap(params => hotelService.getDetails(+params['id'])),
		shareReplay(1)
	)
}

function hotelLocationFactory(hotel$: Observable<HotelDetails>){
	return hotel$.pipe(
		map(hotel => ({
			id: 'hotel',
			otpuskId: hotel.id,
			title: hotel.name
		}))
	)
}