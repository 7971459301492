<div class="customer-info">

	<div class="text-center">
		<h4 i18n="Customer details section title">Customer details</h4>
	</div>

	<div class="grid-x grid-margin-x" [formGroup]="customerInfoForm">

		<div class="name small-6 large-3 cell">
			<label>Name
				<input type="text" formControlName="name" (blur)="onTouched()" />
			</label>
			<div *ngIf="name.invalid && (name.dirty || name.touched)" class="validation-error">
				<p i18n="name form error">Name is not valid</p>
			</div>
		</div>

		<div class="surname small-6 large-3 cell">
			<label>Surname
				<input type="text" formControlName="surname" (blur)="onTouched()" />
			</label>
			<div *ngIf="surname.invalid && (surname.dirty || surname.touched)" class="validation-error">
				<p i18n="surname form error">Surname is not valid</p>
			</div>
		</div>

		<div class="phone small-6 large-3 cell">
			<label>Phone
				<input type="text" formControlName="phone" (blur)="onTouched()" />
			</label>
			<div *ngIf="phone.invalid && (phone.dirty || phone.touched)" class="validation-error">
				<p i18n="phone form error">Phone is not valid</p>
			</div>
		</div>

		<div class="email small-6 large-3 cell">
			<label>Email
				<input type="email" formControlName="email" (blur)="onTouched()" />
			</label>
			<div *ngIf="email.invalid && (email.dirty || email.touched)" class="validation-error">
				<p i18n="email form error">Email is not valid</p>
			</div>
		</div>

	</div>
</div>