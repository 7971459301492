<div class="grid-x">
	<div class="cell small-12 medium-6 text-center">
		<h4>Перелет туда</h4>
		<ng-container *ngIf="flightOptions.outboundFlights && flightOptions.outboundFlights.length > 0">
			<tours-flight *ngFor="let flight of flightOptions.outboundFlights" [value]="flight"></tours-flight>
		</ng-container>
	</div>
	<div class="cell small-12 medium-6 text-center">
		<h4>Перелет обратно</h4>
		<ng-container *ngIf="flightOptions.returnFlights && flightOptions.returnFlights.length > 0">
			<tours-flight *ngFor="let flight of flightOptions.returnFlights" [value]="flight"></tours-flight>
		</ng-container>
	</div>
</div>