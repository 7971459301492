import { Component, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HotelDetails } from '../../../models/HotelDetails';
import { HOTEL_DETAILS, HOTEL_PROVIDER } from './hotel-page.provider';

@Component({
	selector: 'tours-hotel-page',
	templateUrl: './hotel-page.component.html',
	styleUrls: ['./hotel-page.component.scss'],
	providers: [HOTEL_PROVIDER]
})
export class HotelPageComponent {
	constructor(@Inject(HOTEL_DETAILS) public hotelDetails$: Observable<HotelDetails>) { }
}