import { Directive, Inject, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { WINDOW } from '../injectables/window';

@Directive({
	selector: '[dev]'
})
export class DevDirective implements OnInit {

	private readonly PROPERTY = "devMode";

	constructor(
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef,
		@Inject(WINDOW) private window: Window
	) { }


	ngOnInit(): void {
		const localstorage = this.window.localStorage;
		if (typeof localstorage !== 'undefined' && localstorage.getItem(this.PROPERTY) === 'true') {
			this.viewContainer.createEmbeddedView(this.templateRef);
		}
	}

}
