import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Location } from '../../../models/Locations';
import { SearchBase } from '../../../models/SearchBase';
import { SearchRequest } from '../../../models/SearchRequest';
import { OfferId, SearchOffer } from '../../../models/SearchOffer';
import { SubSink } from 'subsink';
import { FormBuilder } from '@angular/forms';
import { HOTEL_LOCATION, HOTEL_PROVIDER } from '../../page/hotel-page/hotel-page.provider';
import { HOTEL_ROUTE, HOTEL_SEARCH_PROVIDER } from './search.provider';
import { SEARCH_PROVIDER } from '../../../shared/providers/routeSearchParamsProvider';
import { HotelSearchService } from '../../services/hotel-search.service';
import { People } from 'tours-lib';
import { FlightOptions } from '../../../models/Flight';
import { MatDialog } from '@angular/material/dialog';
import { FlightDetailsComponent } from '../../../shared/components/flight-details/flight-details.component';

@Component({
	selector: 'tours-search',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.scss'],
	providers: [SEARCH_PROVIDER, HOTEL_PROVIDER, HOTEL_SEARCH_PROVIDER, HotelSearchService]
})
export class SearchComponent extends SearchBase implements OnInit, OnDestroy {

	error: any;
	loading: boolean = false;
	subs = new SubSink();

	readonly filterForm = this.fb.group({
		date: [null]
	});

	readonly filtedResults$: Observable<SearchOffer[]> =
		combineLatest([this.hss.searchResult$, this.filterForm.valueChanges])
			.pipe(
				map(([value, form]) => ([value.offers, form])),
				map(([offers, form]) => offers.filter((offer: SearchOffer) => offer.date === form['date'] || form['date'] === null))
			);

	private readonly state$ = this.hss.state$;
	public readonly loading$ = this.hss.loading$;

	constructor(
		public router: Router,
		@Inject(HOTEL_ROUTE) readonly route$: Observable<SearchRequest>,
		private fb: FormBuilder,
		@Inject(HOTEL_LOCATION) public currentLocation$: Observable<Location>,
		private hss: HotelSearchService,
		private dialog: MatDialog
	) {
		super(router);
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	ngOnInit(): void { }

	public offerSelected(data: OfferId) {
		this.subs.add(
			combineLatest([of(data), this.state$, this.route$])
				.pipe(
					map(([offerId, state, route]) => ({
						offer: offerId.id,
						tourOperatorId: offerId.operatorId,
						people: People.toOtpuskFormat(route.people),
						hash: state.hash
					}))
				).subscribe(queryParams =>
					this.router.navigate(['/reservation/'], { queryParams })
				)
		);
	}

	public flightSelected(flight: FlightOptions) {
		this.dialog.open(FlightDetailsComponent, {
			data: { flightDetails: flight }
		});
	}
}
