import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Location } from '../../../models/Locations';
import { AutocompleteItem } from '../../models/AutocompleteItem';
import { AutocompleteService } from '../../services/autocomplete.service';
import { AUTOCOMPLETE_MAPPER, DESTINATION_PROVIDER } from '../providers/destinationProvider';

@Component({
	selector: 'tours-autocomplete',
	templateUrl: './autocomplete.component.html',
	styleUrls: ['./autocomplete.component.scss'],
	providers: [DESTINATION_PROVIDER]
})
export class AutocompleteComponent {

	readonly input = new FormControl();
	readonly results$ = this.input.valueChanges.pipe(
		debounceTime(400),
		distinctUntilChanged(),
		switchMap(value => this.autocompleteService.request(value))
	);

	@Output() onSelect = new EventEmitter<Location>();

	constructor(
		private autocompleteService: AutocompleteService,
		@Inject(AUTOCOMPLETE_MAPPER) private autocompleteMapper: (item: AutocompleteItem) => Location
	) {}

	public handleItemClick(item: AutocompleteItem){
		this.onSelect.emit(this.autocompleteMapper(item));
	}

}
