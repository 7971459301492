import { Component, Input } from '@angular/core';
import { HotelDesctiption } from '../../../models/HotelDetails';

@Component({
	selector: 'tours-hotel-description',
	templateUrl: './hotel-description.component.html',
	styleUrls: ['./hotel-description.component.scss']
})
export class HotelDescriptionComponent {

	@Input() description!: HotelDesctiption;

	constructor() { }

}
