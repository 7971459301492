import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FlightOptions } from '../../../models/Flight';
import { OfferId, SearchOffer } from '../../../models/SearchOffer';

@Component({
	selector: 'tours-offer',
	templateUrl: './offer.component.html',
	styleUrls: ['./offer.component.scss']
})
export class OfferComponent {

	@Input() offer!: SearchOffer;
	@Output() onOfferSelect: EventEmitter<OfferId> = new EventEmitter();
	@Output() onFlightSelect: EventEmitter<FlightOptions> = new EventEmitter();

	constructor() { }

	public goToReservation() {
		this.onOfferSelect.emit({
			id: this.offer.id,
			operatorId: this.offer.operatorId
		});
	}

	public showFlightTimetable(flights: FlightOptions){
		this.onFlightSelect.emit(flights);
	}
}
