import { InjectionToken, Provider } from "@angular/core";
import { combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Location } from "../../../models/Locations";
import { SearchRequest } from "../../../models/SearchRequest";
import { SEARCH_ROUTE_PARAMS } from "../../../shared/providers/routeSearchParamsProvider";
import { HOTEL_LOCATION } from "../../page/hotel-page/hotel-page.provider";

export const HOTEL_ROUTE = new InjectionToken<Observable<SearchRequest>>(
	'A stream with current hotel aware route params'
);

export const HOTEL_SEARCH_PROVIDER: Provider[] = [
	{
		provide: HOTEL_ROUTE,
		deps: [SEARCH_ROUTE_PARAMS, HOTEL_LOCATION],
		useFactory: hotelRouteFactory
	}
];

function hotelRouteFactory(
	route$: Observable<SearchRequest>,
	currentLocation$: Observable<Location>
) {
	return combineLatest([route$, currentLocation$]).pipe(
		map(([value, hotelLocation]) => ({ ...value, destination: hotelLocation }))
	)
}