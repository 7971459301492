import { Origin } from "../models/Locations";
import { ORIGIN } from "./Origin";

export const ORIGIN_INFO: { [key in ORIGIN]: Origin } = {
	[ORIGIN.KYIV] : {
		id: ORIGIN.KYIV,
		title: `Kyiv`,
		otpuskId: 1544
	},
	[ORIGIN.DNIPRO]: {
		id: ORIGIN.DNIPRO,
		title: `Dnipro`,
		otpuskId: 1874
	}
}