import { Component, Input } from '@angular/core';
import { Coordinates } from '../../../models/Coordinates';

@Component({
	selector: 'tours-map',
	templateUrl: './map.component.html',
	styleUrls: ['./map.component.scss']
})
export class MapComponent {

	@Input() coordinates!: Coordinates;

	constructor() { }

	get mapUrl(): string {
		return "";
	}

}
