import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Country, Resort } from '../../../models/Locations';

@Component({
	selector: 'tours-destination-select',
	templateUrl: './destination-select.component.html',
	styleUrls: ['./destination-select.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: DestinationSelectComponent,//forwardRef(() => CustomerInfoComponent),
			multi: true
		}
	]
})
export class DestinationSelectComponent implements ControlValueAccessor {

	@Output() close = new EventEmitter();

	onTouchedCallback: () => void = () => { };
	onChangedCallback: (value: any) => void = () => { };

	constructor() { }

	writeValue(obj: any): void {}

	registerOnChange(fn: any): void {
		this.onChangedCallback = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}

	selectDestination(destination: Country | Resort){
		this.onChangedCallback(destination);
		this.close.emit();
	}

}
