import { Router } from "@angular/router";
import { COUNTRY_INFO } from "../data/CountryInfo";
import { Country, Resort } from "./Locations";
import { SearchRequest } from "./SearchRequest";

export class SearchBase {

	constructor(public router: Router) { }

	public doSearch(request: SearchRequest) {

		console.log(request);

		const queryParams = {
			currentTime: Date.now(),
			...request.people,
			...request.dateRange,
			...request.duration
		}

		const originSlug = request.origin.id;
		if (request.destination.hasOwnProperty('country')) {

			const destination = request.destination as Resort;
			const resortSlug = destination.id;
			const countrySlug = COUNTRY_INFO[destination.country].id;

			this.router.navigate([`/${originSlug}/${countrySlug}/${resortSlug}`], { queryParams });
			return;
		}

		if (request.destination.id === 'hotel') {
			this.router.navigate(['/hotel', request.destination.otpuskId], { queryParams: { ...queryParams, origin: request.origin.id } });
			return;
		}

		const destination = request.destination as Country;
		const countrySlug = destination.id;

		this.router.navigate([`/${originSlug}/${countrySlug}`], { queryParams });

	}
}
