<div class="grid-x calendar align-center text-center" *ngIf="(loading$ | async) !== true">
	<div class="cell small-12 medium-10 large-8">

		<div class="grid-x small-up-7 weekdays">
			<div class="cell">пн</div>
			<div class="cell">вт</div>
			<div class="cell">ср</div>
			<div class="cell">чт</div>
			<div class="cell">пт</div>
			<div class="cell">сб</div>
			<div class="cell">вс</div>
		</div>

		<div class="grid-x small-up-7 meta">

			<div
				*ngFor="let day of (days$ | async); let i = index"
				(click)="!!day.price && daySelected(day)"
				class="cell day"
				[ngClass]="{'active': day.value === selectedValue, 'inactive': !day.price}"
			>
				<div class="day-of-month">{{ day.date.format('D') }}</div>
				<ng-container *ngIf="day.price; else empty">
					<lib-basic-price [price]="day.price"></lib-basic-price>
				</ng-container>
			</div>

		</div>

	</div>
</div>

<ng-template #empty>
	<span class="price">&mdash;</span>
</ng-template>