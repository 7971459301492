<div class="flight">
	<div class="origin text-center">
		<div class="date">{{ departureDate }}</div>
		<div class="time">{{ departureTime }}</div>
		<div class="airport-name">{{ originAirportName }}</div>
		<div class="airport-code">{{ flight.origin }}</div>
	</div>
	<div class="basic-info text-center">
		<div class="flight-duration">{{duration}}</div>
		<hr />
		<small class="carrier">{{flight.carrier}}</small>
	</div>
	<div class="destination text-center">
		<div class="date">{{ landingDate }}</div>
		<div class="time">{{ landingTime }}</div>
		<div class="airport-name">{{ destinatinAirportName }}</div>
		<div class="airport-code">{{ flight.destination }}</div>
	</div>
</div>