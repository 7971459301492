import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Currency } from '../models/Currency';

@Injectable({
	providedIn: 'root'
})
export class CurrentCurrencyService {
	currentCurrency$ = new BehaviorSubject<Currency>(Currency.EUR);

	constructor() { }

	public get(): Observable<Currency> {
		return this.currentCurrency$.asObservable();
	}

	public set(currency: Currency) {
		this.currentCurrency$.next(currency);
	}
}