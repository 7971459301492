import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { People } from 'tours-lib';
import { COUNTRY } from '../../../data/Country';
import { COUNTRY_INFO } from '../../../data/CountryInfo';
import { ORIGIN } from '../../../data/Origin';
import { ORIGIN_INFO } from '../../../data/OriginInfo';
import { DateRange } from '../../../models/DateRange';
import { Location, Origin } from '../../../models/Locations';
import { SearchRequest } from '../../../models/SearchRequest';


@Component({
	selector: 'tours-search-bar',
	templateUrl: './search-bar.component.html',
	styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent {

	searchForm: FormGroup;
	@Output() search = new EventEmitter<SearchRequest>();
	
	constructor(private fb: FormBuilder) { 

		const people = new People(2, []);
		const duration = {
			durationFrom: 8,
			durationTo: 14
		}
		const destination = COUNTRY_INFO[COUNTRY.EGYPT];
		const origin = ORIGIN_INFO[ORIGIN.KYIV];

		const daysDiff = 5;
		const daysOffset = 4;
		const timezomeOffsetMin = (new Date()).getTimezoneOffset();

		let today = new Date();
		
		const checkIn = new Date(today.getTime() + (timezomeOffsetMin * 60 * 1000) + (daysOffset * 24 * 60 * 60 * 1000 ));
		const checkInTo = new Date(today.getTime() + (timezomeOffsetMin * 60 * 1000) + ((daysOffset + daysDiff) * 24 * 60 * 60 * 1000));

		const dateRange: DateRange = {
			checkIn: checkIn.toISOString().split('T')[0],
			checkInTo: checkInTo.toISOString().split('T')[0]
		}

		this.searchForm = fb.group({
			origin: [origin],
			duration: [duration],
			people: [people],
			destination: [destination],
			dateRange: [dateRange]
		});
	}

	submitSearch() {
		this.search.emit(this.searchForm.value);
	}

	get durationString() {
		return `from ${this.searchForm.get('duration')?.value.durationFrom} to ${this.searchForm.get('duration')?.value.durationTo}`;
	}


	get peopleString() {
		return `${this.searchForm.get('people')?.value.adults} adults ${this.searchForm.get('people')?.value.children.length} children`;
	}

	get destinationString() {
		return `${this.searchForm.get('destination')?.value.title}`;
	}

	get originString() {
		return `${this.searchForm.get('origin')?.value.title}`;
	}

	@Input() set destination(destination: Location){
		if (!destination) return;
		this.searchForm.get('destination')?.patchValue(destination);
	}

	@Input() set origin(origin: Origin){
		this.searchForm.get('origin')?.patchValue(origin);
	}

	@Input() set params(params: SearchRequest | null) {
		if(!params) return;
		this.searchForm.patchValue(params);
	}
/*

	searchForm: FormGroup;
	destinationOptions$: Observable<Destination[]>;

	tripTypes: RadioValues[] = [{
		value: TripType.OneWay,
		label: $localize`:@@triptype.one-way:One-way`
	}, {
		value: TripType.Return,
		label: $localize`:@@triptype.return:Return trip`
	}];

	@Output() search = new EventEmitter<SearchBarValues>();

	constructor(private fb: FormBuilder, private destinationsService: DestinationsService) {

		const origin = DESTINATIONS['kyiv'];
		const destination = DESTINATIONS['sharm-el-sheikh'];

		const today = moment.utc();
		const departureDefault = today.clone().add(14, 'days');
		const returnDefault = today.clone().add(21, 'days');

		const people = new People(1, []);

		this.searchForm = fb.group({
			origin: [origin, [Validators.required, RequireMatch]],
			destination: [destination, [Validators.required, RequireMatch]],
			type: [TripType.OneWay, Validators.required],
			departureDate: [departureDefault, [Validators.required]],
			returnDate: [{ value: returnDefault, disabled: true }, [Validators.required]],
			people: [people]
		});

		this.destinationOptions$ =
			this.searchForm.get('origin')!.valueChanges
				.pipe(
					startWith(origin),
					filter(value => !!value && value.hasOwnProperty('destinationId')),
					distinctUntilChanged((p: Destination, q: Destination) => q.destinationId === p.destinationId),
					switchMap(value => this.destinationsService.to(value))
				);

		this.searchForm.get('type')
			?.valueChanges
			.subscribe(value => {
				if (TripType.OneWay === value) {
					this.searchForm.get('returnDate')?.disable();
				} else {
					this.searchForm.get('returnDate')?.enable();
				}
			})

	}



	@Input() set origin(value: string) {
		if (DESTINATIONS.hasOwnProperty(value.toLowerCase())) {
			// @ts-ignore
			this.searchForm.controls.origin.setValue(DESTINATIONS[value]);
		}
	}

	@Input() set destination(value: string) {
		if (DESTINATIONS.hasOwnProperty(value.toLowerCase())) {
			// @ts-ignore
			this.searchForm.controls.destination.setValue(DESTINATIONS[value]);
		}
	}

	@Input() set outboundDate(value: string | moment.Moment) {
		if (moment.isMoment(value)) {
			this.searchForm.controls.departureDate.setValue(moment.utc(value));
		} else {
			const date = moment.utc(value, URL_DATE_FORMAT);
			if (date.isValid()) {
				this.searchForm.controls.departureDate.setValue(date);
			}
		}
	}

	@Input() set returnDate(value: string | moment.Moment) {
		if (moment.isMoment(value)) {
			this.searchForm.controls.type.setValue(TripType.Return);
			this.searchForm.controls.returnDate.setValue(moment.utc(value));
		} else {
			const date = moment.utc(value, URL_DATE_FORMAT);
			if (date.isValid()) {
				this.searchForm.controls.type.setValue(TripType.Return);
				this.searchForm.controls.returnDate.setValue(date);
			}
		}
	}

	@Input() set people(value: People) {
		this.searchForm.controls.people.setValue(value);
	}*/
}
