import { Component, Inject, Input } from '@angular/core';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/duration';
import * as duration from 'dayjs/plugin/utc';
import * as isDST from 'dayjs/plugin/isDST';
import { Airport, AIRPORTS } from 'tours-lib';
import { Flight } from '../../../models/Flight';

dayjs.extend(utc)
dayjs.extend(duration)
dayjs.extend(isDST)

@Component({
	selector: 'tours-flight',
	templateUrl: './flight.component.html',
	styleUrls: ['./flight.component.scss']
})
export class FlightComponent {

	flight!: Flight;

	private _departureTime!: dayjs.Dayjs;
	private _landingTime!: dayjs.Dayjs;

	private DATE_FORMAT = 'D/MM';
	private TIME_FORMAT = 'HH:mm';

	duration?: string;

	constructor(@Inject(AIRPORTS) private airports: { [key: string]: Airport }) { }

	get originAirportName() {
		return this.airports[this.flight.origin].name;
	}

	get destinatinAirportName() {
		return this.airports[this.flight.destination].name;
	}

	get departureDate() {
		return this._departureTime.format(this.DATE_FORMAT);
	}

	get departureTime() {
		return this._departureTime.format(this.TIME_FORMAT);
	}

	get landingDate() {
		return this._landingTime.format(this.DATE_FORMAT);
	}

	get landingTime() {
		return this._landingTime.format(this.TIME_FORMAT);
	}

	@Input() set value(value: Flight) {
		this.flight = value;

		this._departureTime = dayjs.utc(this.flight.departure);
		this._landingTime = dayjs.utc(this.flight.landing);

		this.duration = this.calculateDuration(
			this._departureTime,
			this._landingTime,
			this.airports[this.flight.origin],
			this.airports[this.flight.destination]
		);
	}

	private calculateDuration(departureTime: dayjs.Dayjs, arrivalTime: dayjs.Dayjs, originAirport: Airport, destinationAirport: Airport): string {

		const originTimezone = (departureTime.isDST()) ? originAirport.dst : originAirport.std;
		const destinationTimezone = (arrivalTime.isDST()) ? destinationAirport.dst : destinationAirport.std;

		const originTimezoneDuration = dayjs.duration(originTimezone);
		const destinationTimezoneDuration = dayjs.duration(destinationTimezone);

		const diff = dayjs.duration(arrivalTime.diff(departureTime));

		diff.add(originTimezoneDuration);
		diff.subtract(destinationTimezoneDuration);

		return dayjs.utc(diff.as('milliseconds')).format("H:mm");
	}
}