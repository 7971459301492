import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, InjectionToken, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'projects/tours/src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, delay, filter, map, shareReplay, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { InvoiceStatus } from '../../models/InvoiceStatus';
import { OrderStatus } from '../../models/OrderStatus';
import { StatusService } from '../../services/status.service';

@Component({
	selector: 'tours-status',
	templateUrl: './status.component.html',
	styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnDestroy {

	readonly orderStatus$: Observable<OrderStatus> = this.route.params.pipe(
		switchMap(({ orderNumber, surname }) => this.statusService.get(orderNumber, surname)),
		shareReplay(1),
		catchError(error => this.redirectWithError(error))
	);
	readonly overlayRef = this.overlay.create({
		positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
		backdropClass: 'overlay-backdrop',
		hasBackdrop: true
	});

	public TIME_TO_REDIRECT = environment.redirectDelay;

	private sink = new SubSink();

	constructor(
		private route: ActivatedRoute,
		private statusService: StatusService,
		private overlay: Overlay,
		@Inject(DOCUMENT) private document: Document,
		private router: Router,
		private injetor: Injector
	) {

		this.sink.add(
			this.route.queryParams
				.pipe(
					filter(values => values.hasOwnProperty('redirect')),
					switchMap(_ => this.orderStatus$),
					map(status => status.invoices.find(invoice => invoice.status === 'issued' && invoice.hasOwnProperty('link'))),
					filter(activeInvoice => !!activeInvoice),
					tap(activeInvoice => {
						this.overlayRef.attach(
							new ComponentPortal(
								LoadingOverlay,
								null,
								this.createInjectorForLoading(activeInvoice!.link!, this.TIME_TO_REDIRECT)
							)
						);
					}),
					delay(this.TIME_TO_REDIRECT),
				).subscribe(activeInvoice => {
					this.overlayRef.detach();
					if (activeInvoice && activeInvoice.link && this.document.location && this.document.location.href) {
						this.document.location.href = activeInvoice.link;
					}
				})
		);
	}

	ngOnDestroy(): void {
		this.sink.unsubscribe();
	}

	private redirectWithError(error: any) {
		this.router.navigate(['/status'], { queryParams: { error: true } });
		return throwError('Could not order. Redirecting...')
	}

	private createInjectorForLoading(url: string, time: number) {
		return Injector.create({
			providers: [{
				provide: REDIRECT_LINK,
				useValue: url
			}, {
				provide: TIME_TO_REDIRECT,
				useValue: time
			}],
			parent: this.injetor
		});
	}
}


@Component({
	selector: 'redirect-ovelay',
	template: `
	<div class="content">
		<p>Вы будете переадресованы на страницу оплаты через {{ timeInSec }} сек.</p>
		<small>Если переадресация не произошла автоматически, нажмите сюда: <a [href]="redirectLink">перейти к оплате</a></small>
	</div> 
	`,
	styles: ['.content { color: white; text-align: center; }']
})
export class LoadingOverlay {
	constructor(
		@Inject(REDIRECT_LINK) public redirectLink: string,
		@Inject(TIME_TO_REDIRECT) public timeToRedirect: number,
	) { }

	get timeInSec(){
		return Math.floor(this.timeToRedirect / 1000)
	}
}

const TIME_TO_REDIRECT = new InjectionToken<number>('Time to redirect');
const REDIRECT_LINK = new InjectionToken<string>('Redirect link');