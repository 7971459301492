<ng-container *ngIf="(currentLocation$ | async) as currentLocation">
	<tours-search-bar
		[params]="route$ | async"
		[destination]="currentLocation"
		(search)="doSearch($event)">
	</tours-search-bar>
</ng-container>

<div *dev>
	{{ filterForm.value | json }}
</div>

<ng-container [formGroup]="filterForm">
	
	<tours-days formControlName="date"></tours-days>

	<ng-container *ngIf="(loading$ | async) === true">
		<app-loading-airplane></app-loading-airplane>
	</ng-container>

	<ng-container *ngIf="(filtedResults$ | async) as result">
		<ng-container *ngFor="let offer of result">
			<tours-offer
				[offer]="offer"
				(onOfferSelect)="offerSelected($event)"
				(onFlightSelect)="flightSelected($event)"
			></tours-offer>
			<hr />
		</ng-container>
	</ng-container>

</ng-container>