import { DOCUMENT } from '@angular/common';
import { Directive, HostListener, Inject, Input } from '@angular/core';

interface PhotoSwipeItem {
	src: string;
	w: number;
	h: number;
	processed: boolean;
}

@Directive({
	selector: '[photo-swipe]'
})
export class PhotoSwipeDirective {

	private element: Element | null;
	private photosArray: PhotoSwipeItem[] = [];

	constructor(@Inject(DOCUMENT) private document: Document) {
		this.element = this.document.querySelector('.pswp');
	}

	@Input() set photos(photos: string[]) {
		this.photosArray = photos.map(photo => ({
			src: photo,
			w: 800,
			h: 600,
			processed: false
		}));
	}

	@HostListener("click", ['$event']) onClick(event: Event){

		const target = event.target as Element;

		let index;
		if(target.hasAttribute('data-index')){
			index = parseInt(target.getAttribute('data-index') || '0')
		} else {
			const parentWithIndex = target.closest('[data-index]');
			if (parentWithIndex) {
				index = parseInt(parentWithIndex.getAttribute('data-index') || '0')
			} else {
				console.warn('Element has no data-index attribute')
			}
		}

		if (Number.isInteger(index) && this.element){
			console.log(index)
			//@ts-ignore
			const gallery = new PhotoSwipe(this.element, PhotoSwipeUI_Default, this.photosArray, { index });

			gallery.listen('gettingData', (index: number, item: PhotoSwipeItem) => {
				if (!item.processed) {
					const img = new Image();
					img.onload = function () {
						item.w = img.width;
						item.h = img.height;
						item.processed = true;
						//scope.gallery.object.invalidateCurrItems(); // reinit Items
						gallery.updateSize(true);
					}
					img.src = item.src;
				}
			});

			gallery.init();
		}
	}
}