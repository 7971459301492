import { Inject, Injectable, OnDestroy } from "@angular/core";
import { combineLatest, EMPTY, Observable, of, ReplaySubject } from "rxjs";
import { catchError, map, shareReplay, switchMap, tap } from "rxjs/operators";
import { UnsubscribeOnDestroy } from "tours-lib";
import { SearchRequest } from "../../models/SearchRequest";
import { SearchState } from "../../models/SearchState";
import { SearchService } from "../../shared/services/search.service";
import { HOTEL_ROUTE } from "../components/search/search.provider";


@Injectable()
export class HotelSearchService extends UnsubscribeOnDestroy implements OnDestroy {

	private readonly searchState$: ReplaySubject<SearchState> = new ReplaySubject(1);
	private readonly loadingStream$: ReplaySubject<boolean> = new ReplaySubject(1);
	private readonly errorsStream$: ReplaySubject<string> = new ReplaySubject(1);

	public readonly searchResult$ = this.searchState$
		.pipe(
			switchMap(state => 
				combineLatest([
					of(state),
					this.searchService.queryResultForHotel(state)
						.pipe(
							catchError(this.handleError.bind(this))
						)
				])),
			tap(([state, result]) => {
				if (!result.completed) {
					setTimeout(() => this.searchState$.next(state), 500);
				}
			}),
			map(([state, result]) => result),
			tap(result => this.loadingStream$.next(!result.completed)),
			shareReplay(1)
		);

	constructor(@Inject(HOTEL_ROUTE) private route$: Observable<SearchRequest>, private searchService: SearchService) {
		super();
		this.sink.add(this.route$.pipe(
			tap(() => this.loadingStream$.next(true)),
			switchMap(value => 
				this.searchService.search(value)
					.pipe(
						catchError(this.handleError.bind(this))
					)
			),
		).subscribe(hash => {
			this.searchState$.next(new SearchState(hash));
		}))
	}

	get state$() {
		return this.searchState$.asObservable();
	}

	get loading$() {
		return this.loadingStream$.asObservable();
	}

	get errors$() {
		return this.errorsStream$.asObservable();
	}

	private handleError(error: any) {
		this.loadingStream$.next(false);
		this.errorsStream$.next(error);
		return EMPTY;
	}
}