<div class="grid-x hotel-preview medium-grid-padding-y"
	[ngClass]="{'recommended': hotel.recommended, 'covid-safe': hotel.amenities.includes('covid') }">
	<div class="cell auto">
		<div class="grid-x medium-grid-margin-x">
			<div class="small-12 medium-4 large-3 cell image-block show-for-medium"
				[style.background-image]="'url(' + hotel.photo + ')'" [attr.data-hotel-id]="hotel.id">
				<div class="labels-block">
					<div class="recommended-label">
						Рекомендованный отель
					</div>
					<div class="certificate-label">
						<i class="fas fa-certificate"></i>&nbsp;Сертификат безопасности
					</div>
				</div>
			</div>
			<div class="small-12 medium-auto cell">
				<div class="hotel-header border-bottom" [style.background-image]="'url(' + hotel.photo + ')'">
					<div class="labels-block">
						<div class="recommended-label">Рекомендованный отель</div>
					</div>
					<div class="gradient-wrap">
						<div class="title-block">
							<div class="title-row">
								<div class="hotel-stars">
									<app-stars [stars]="hotel.stars"></app-stars>
								</div>
								<div class="hotel-title">
									<a [routerLink]="routerLink" [queryParams]="additionalQueryParams" queryParamsHandling="merge">
										<h3>{{ hotel.name }}</h3>
									</a>
								</div>
							</div>
							<div class="hotel-location">
								<i class="fas fa-map-marker-alt"></i><span>{{hotel.location}}</span>
							</div>
						</div>
						<div class="rating-block">
							<div class="rating booking" *ngIf="!!hotel.rating.booking">
								<a [href]="hotel.ratingUrls.booking" target="_blank">
									<div class="rating-number">{{hotel.rating.booking}}</div>
									<div>booking</div>
								</a>
							</div>
							<div class="rating tripadvisor" *ngIf="!!hotel.rating.tripadvisor">
								<a [href]="hotel.ratingUrls.tripadvisor" target="_blank">
									<div class="rating-number">{{hotel.rating.tripadvisor}}</div>
									<div>tripadvisor</div>
								</a>
							</div>
							<div class="rating otpusk" *ngIf="!!hotel.rating.otpusk">
								<div class="rating-number">{{hotel.rating.otpusk}}</div>
								<div>турправда</div>
							</div>
						</div>
						<!--covid info -->
						<div class="certificate-block">
							<span class="certificate-covid"><i class="fas fa-certificate"></i>&nbsp;Сертификат
								безопасности</span>
						</div>
					</div>
				</div>
				<div class="grid-x border-bottom grid-padding-x medium-padding-collapse"
					ng-if="hotel.amenities.length > 0">
					<div class="cell">
						<div class="grid-x small-up-2 medium-up-3 large-up-6 hotel-amenities">
							<div class="cell" *ngIf="hotel.amenities.includes('one_line_beach')">
								<i class="fas fa-water"></i>Первая линия
							</div>
							<div class="cell" *ngIf="hotel.amenities.includes('two_line_beach')">
								<i class="fas fa-water"></i>Вторая линия
							</div>
							<div class="cell" *ngIf="hotel.amenities.includes('own')">
								<i class="fas fa-swimmer"></i>Свой пляж
							</div>
							<div class="cell" *ngIf="hotel.amenities.includes('wifi')">
								<i class="fas fa-wifi"></i>Wi-Fi
							</div>
							<div class="cell" *ngIf="hotel.amenities.includes('sandy')">
								<i class="fas fa-umbrella-beach"></i>Песчаный пляж
							</div>
							<div class="cell" *ngIf="hotel.amenities.includes('pebble')">
								<i class="fas fa-umbrella-beach"></i>Галечный пляж
							</div>
							<div class="cell" *ngIf="hotel.amenities.includes('adults_only')">
								<i class="fas fa-exclamation"></i>Только для взрослых
							</div>
						</div>
					</div>
				</div>

				<!--Hotel short description -->
				<div class="hotel-excerpt" *ngIf="!!hotel.excerpt" [innerHTML]="hotel.excerpt"></div>

				<!--Representaion for medium screens-->
				<div class="best-offer">
					<div class="grid-x grid-padding-x medium-padding-collapse show-for-medium">
						<div class="small-12 medium-auto cell">
							<div class="grid-x small-up-2 medium-up-2 large-up-4 tour-info">
								<div class="cell nowrap"><i class="far fa-sun"></i> Тур на {{hotel.cheapestOffer.duration}} дней</div>
								<div 
									class="cell nowrap"
									(click)="showFlightTimetable(hotel.cheapestOffer.flightOptions)"
									*ngIf="hotel.cheapestOffer.flightOptions" 
								>
									<i class="fas fa-plane"></i>&nbsp;<span class="flight-time">Время перелетов</span>
								</div>
								<div class="cell nowrap"><i class="fas fa-utensils"></i>&nbsp;{{ hotel.cheapestOffer.food | food }}</div>
								<div class="cell nowrap"><i class="far fa-calendar-alt"></i>&nbsp;Вылет {{hotel.cheapestOffer.date}}</div>
								<div class="cell nowrap instant-book" *ngIf="hotel.cheapestOffer.instant">
									<i class="fas fa-check-circle"></i>&nbsp;Гарантированное&nbsp;подтверждение</div>
							</div>
						</div>
						<app-price [price]="hotel.cheapestOffer.extendedPrice"></app-price>
						<div class="small-12 medium-shrink cell">
							<div
								(click)="goToReservation()"
								class="button">
								Купить
						</div>
						</div>
					</div>
				</div>

				<!--Text representaion for small screens-->
				<div class="best-offer show-for-small-only">
					<div class="grid-x">
						<div class="auto cell text-representation">
							<div><i class="far fa-sun"></i> Тур на {{hotel.cheapestOffer.duration}} дней</div>
							<div
								*ngIf="hotel.cheapestOffer.flightOptions" 
								(click)="showFlightTimetable(hotel.cheapestOffer.flightOptions)"
								class="flight-time"
							>
								<i class="fas fa-plane"></i>&nbsp;<span>Время перелетов</span>
							</div>
							<div><i class="fas fa-utensils"></i>&nbsp;{{ hotel.cheapestOffer.food | food }}</div>
							<div><i class="far fa-calendar-alt"></i>&nbsp;Вылет {{hotel.cheapestOffer.date}}</div>
						</div>
						<app-price [price]="hotel.cheapestOffer.extendedPrice"></app-price>
						<div class="shrink cell">
							<div 
								(click)="goToReservation()"
								class="button"
							>
								Купить
							</div>
						</div>
					</div>
					<div class="grid-x instant-book" *ngIf="hotel.cheapestOffer.instant">
						<div class="auto cell">
							<i class="fas fa-check-circle"></i>&nbsp;Моментальное&nbsp;подтверждение
						</div>
					</div>
				</div>

				<div class="grid-x more-tours-row">
					<div class="small-12 medium-auto cell">
						<a [routerLink]="routerLink" [queryParams]="additionalQueryParams" queryParamsHandling="merge">
							<div class="text-center more-num-tours" *ngIf="hotel.numOffers > 1">
								<span>Еще <strong>{{hotel.numOffers}}+</strong> вариантов туров в
									этот отель&nbsp;&rarr;</span>
							</div>
							<div class="text-center more-num-tours" *ngIf="hotel.numOffers == 1">
								<span>Другие варианты туров в этот отель&nbsp;&rarr;</span>
							</div>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>