<div class="text-center">
	<h4>Passports</h4>
</div>

<ng-container [formGroup]="passportSelectForm">
<div class="passports-select-block block-bottom-margin">
	<label for="delayedPassports">
		<div class="grid-x">
			<div class="small-1 cell">
				<div class="radio-container float-center">
					<input type="radio" id="delayedPassports" formControlName="type" value="delayedPassports" (blur)="onTouched()" />
					<div class="checkmark"></div>
				</div>
			</div>
			<div class="auto cell">
				<p>Я вишлю паспорти подорожуючих вам на <a href="mailto:clevertravel.com.ua@gmail.com"
						target="_blank">email</a> або у <a href="//www.facebook.com/clevertravel.com.ua"
						target="_blank">facebook</a>.</p>
				<small ng-show="!!$ctrl.delayedPassports">
					<strong>Важливо!</strong>
					Ми не можемо почати бронювання туру без паспортних даних, тому бажано відправити скани або фото
					паспортів одразу після оформлення замовлення.
				</small>
			</div>
		</div>
	</label>
	<label for="instantPassports">
		<div class="grid-x">
			<div class="small-1 cell">
				<div class="radio-container float-center">
					<input type="radio" id="instantPassports" formControlName="type" value="instantPassports" (blur)="onTouched()"/>
					<div class="checkmark"></div>
				</div>
			</div>
			<div class="auto cell">
				<p>Я введу паспортні дані зараз.</p>
			</div>
		</div>
	</label>
</div>

<ng-container *ngIf="passportSelectForm.get('type')!.value == 'instantPassports'">
	
		<ng-container formArrayName="passports">
			<div *ngFor="let isChild of passportValuesArray.controls; let i = index" class="passport-form">
				<app-passport [formControlName]="i"></app-passport>
			</div>
		</ng-container>

</ng-container>
<div *dev>
	{{ passportSelectForm.value | json }}
</div>
</ng-container>