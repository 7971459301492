import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class TosService {

	constructor(@Inject(TOS_URL_TOKEN) private url: string, private httpClient: HttpClient) { }

	public get(): Observable<string> {
		const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
		return this.httpClient.get<string>(this.url, { headers, responseType: 'text' as 'json' });
	}
}

export const TOS_URL_TOKEN = new InjectionToken<string>('URI of Terms of Conditions');
