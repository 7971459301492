<div class="people-select-dropdown">
	<h4 class="show-for-small-only">Кол-во путешествующих</h4>
	<div class="adults-select">
		<div
			*ngFor="let numAdults of adultsArray"
			(click)="setAdults(numAdults)"
			[ngClass]="{'selected': numAdults === adults}"
		>
			{{ numAdults }}
		</div>
	</div>
	<div class="children-list">
		<div *ngFor="let child of children; let i = index">
			<div class="age">{{ child }} y o</div>
			<div class="remove" (click)="removeChild(i)">&times;</div>
		</div>
	</div>
	<div class="add-child" *ngIf="children.length < 4">
		<select [formControl]="childAgeSelect">
			<option *ngFor="let age of childrenAges" [value]="age.value">{{age.text}}</option>
		</select>
	</div>
</div>