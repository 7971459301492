<div class="status grid-container">
	<ng-container *ngIf="(orderStatus$ | async) as orderStatus; else loading">
		<div class="grid-x align-center">
			<div class="cell small-12 medium-11 large-10">

				<h1 class="text-center">
					Заказ №{{ orderStatus.id }}
				</h1>

				<div class="callout alert" *ngIf="orderStatus.needPassports">
					<h4>Паспортные данные не указаны</h4>
					<p>
						Мы не можем начать бронирование тура у туроператора без паспортных данных.
						Отправить сканы или фото заграничных паспортов всех туристов можно нам
						на <a href="mailto:clevertravel.com.ua@gmail.com" target="_blank">email</a>
						или в <a href="//www.facebook.com/clevertravel.com.ua" target="_blank">facebook</a>.
					</p>
				</div>

				<container-element [ngSwitch]="orderStatus.paymentType">
					<div *ngSwitchCase="'liqpay'">
						<tours-invoice *ngFor="let invoice of orderStatus.invoices" [invoice]="invoice"></tours-invoice>
					</div>
					<div *ngSwitchDefault>
						<h4>Оплата на расчётный счёт</h4>
						<p>
							<strong>Реквизиты для оплаты:</strong>
							Одержувач: ТОВ КЛЕВЕР ТРЕВЕЛ<br />
							IBAN: UA533052990000026007036207657<br />
							ЄДРПОУ 41625958<br />
							Призначення платежу: Бронювання №{{ orderStatus.id }}<br />
						</p>
					</div>
				</container-element>


				<div class="row column steps-list">
					<ol>
						<li class="done">
							<h4>Оформление заявки у туроператора</h4>
							<p>
								После внесения предоплаты мы отправляем вашу заявку туроператору.
								Вы получите смс-сообщие с ее номером.
							</p>
						</li>
						<li [ngClass]="{ 'done': orderStatus.status === 'confirmed' }">
							<h4>Подтверждение тура туроператором</h4>
							<p>
								Об изменении статуса заявки туроператором мы сообщим вам по SMS и электронной почте.
								Если туроператор ее подтверждает, в письме вы найдете счет для оплаты остальной части
								тура на счет туроператора. В случае неподтверждения тура - мы полностью возвращаем вам
								предоплату.
							</p>
							<p>Обычно туроператоры подтверждают заявку в течении 1 рабочего дня.</p>
						</li>
						<li [ngClass]="{ 'active': orderStatus.status == 'confirmed' }">
							<h4>Полная оплата тура</h4>
							<p>Вы оплачиваете счет, выставленный туроператором.</p>
						</li>
						<li>
							<h4>Получение документов</h4>
							<p>
								Когда документы будут сформированы туроператором,
								мы сразу отправим их вам на почту.
							</p>
						</li>
					</ol>
				</div>
			</div>
		</div>
	</ng-container>
</div>

<ng-template #loading>
	<app-spinner></app-spinner>
</ng-template>