import { InjectionToken, Provider } from "@angular/core";
import { COUNTRY_INFO } from "../../../data/CountryInfo";
import { RESORT_INFO } from "../../../data/ResortInfo";
import { Country, Resort } from "../../../models/Locations";
import { AutocompleteItem } from "../../models/AutocompleteItem";

export const DESTINATIONS = new InjectionToken(
	'list of destination countries'
);

export const RESORTS = new InjectionToken(
	'list of resorts'
);

export const AUTOCOMPLETE_MAPPER = new InjectionToken(
	'Mapper for autocomplete values into Location'
);

export const DESTINATION_PROVIDER: Provider[] = [
	{
		provide: DESTINATIONS,
		useFactory: destinationFactory
	},
	{
		provide: RESORTS,
		useValue: RESORT_INFO
	},
	{
		provide: AUTOCOMPLETE_MAPPER,
		useFactory: autocompleteMapperFactory
	}
];

function destinationFactory(){
	return Object.values(COUNTRY_INFO);
}

function autocompleteMapperFactory(countriesList: Country[]){

	const resorts = Object.values(RESORT_INFO);

	return (item: AutocompleteItem) => {
		if (item.hotelIds && item.hotelIds.length > 0) {
			return {
				id: 'hotel',
				title: item.name,
				otpuskId: item.hotelIds[0]
			}
		} else if (item.resortIds && item.resortIds.length > 0) {
			return resorts.find(element => element.otpuskId === item.resortIds[0]) as Resort;
		} else {
			return countriesList.find(element => element.otpuskId === item.countryId) as Country;
		}
	}
}