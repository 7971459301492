export enum RESORT {
	SHARM = "sharm-el-sheikh",
	HURGHADA = "hurghada",
	MARSAALAM = "marsa-alam",
	ANTALYA = "antalya",
	IZMIR = "izmir",
	ALANYA = "alanya",
	LARNACA = "larnaca",
	PATHOS = "pathos",
/*	
	SHARJAH = "sharjah",
	TIRANA = "tirana",
	BURGAS = "burgas",
	VARNA = "varna",
	SOFIA = "sofia",
	HERAKLION = "heraklion",
	LAROMANA = "la-romana",
	PUNTACANA = "punta-cana",	
	MOMBASA = "mombasa",
	MALE = "male",
	DUBAI = "dubai",
	ZANZIBAR = "zanzibar",
	MONASTIR = "monastir",
	HAMMAMET = "hammamet",
	DALAMAN = "dalaman",
	KAYSERI = "kayseri",
	ISTANBUL = "istanbul",
	BODRUM = "bodrum",
	ZAGREB = "zagreb",
	PULA = "pula",
	SPLIT = "split",
	BELGRADE = "belgrade",
	DNIPRO = "dnipro",
	ZAPORIZHZHIA = "zaporizhzhia",
	KYIV = "kyiv",
	LVIV = "lviv",
	MYKOLAIV = "mykolaiv",
	ODESA = "odesa",
	SKOPJE = "skopje",
	PODGORICA = "podgorica",
	TIVAT = "tivat",
	KHARKIV = "kharkiv",
	KHERSON = "kherson",
	CHERNIVTSI = "chernivtsi",
	RHODES = "rhodes",
	ATHENS = "athens",
	TENERIFE = "tenerife",
	GRANCANARIA = "gran-canaria",
	FUERTEVENTURA = "fuerteventura",
	MADRID = "madrid",
	BARCELONA = "barcelona",
	VALENCIA = "valencia",
	ALICANTE = "alicante",
	MALLORCA = "mallorca",
	MALAGA = "malaga",	
	ANKARA = "ankara",
	NEVSEHIR = "nevsehir",
	ALMATY = "almaty",
	ZAKYNTHOS = "zakynthos",	
	DUBROVNIK = "dubrovnik",
	TBILISI = "tbilisi",
	BATUMI = "batumi",
	THESSALINIKI = "thessaloniki",
	CORFU = "corfu",
	COLOMBO = "colombo",
	AMMAN = "amman"*/
}