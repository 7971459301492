<div class="row coupon-wrap">
	<div class="small-12 medium-10 large-8 columns medium-centered">
		<div class="row collapse" *ngIf="!show && !validCoupon">
			<div class="small-12 columns">
				<div class="coupon-text">
					<span (click)="toggle()">Маєте купон?</span>
				</div>
			</div>
		</div>
		<div class="row collapse" *ngIf="show && !validCoupon">
			<div class="small-12 medium-10 large-8 columns end">
				<div class="input-group">
					<input class="input-group-field" type="text" [formControl]="couponField">
					<div class="input-group-button">
						<input type="button" class="button" (click)="checkCoupon()"
							value="Застосувати" [disabled]="loading" />
					</div>
				</div>
			</div>
		</div>
		<div class="row collapse" *ngIf="validCoupon">
			<div class="small-12 medium-10 large-8 columns end">
				Купон:&nbsp;<strong>{{validCoupon}}</strong> Знижка:&nbsp;{{discount}}&nbsp;грн. 
				<strong (click)="removeCoupon()" class="remove">&times;</strong>
			</div>
		</div>
		<div class="row collapse" *ngIf="error">
			<div class="small-12 medium-10 large-8 columns end">
				<small class="error">{{error}}</small>
			</div>
		</div>
	</div>
</div>