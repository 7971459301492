import { Pipe, PipeTransform } from '@angular/core';
import { Currency } from '../models/Currency';

@Pipe({
	name: 'currency'
})
export class CurrencyPipe implements PipeTransform {

	transform(value: Currency): string {
		switch (value) {
			case Currency.UAH: return 'грн'
			case Currency.USD: return "&dollar;"
			case Currency.EUR: return '&euro;'
			default: return ''
		}
	}

}
