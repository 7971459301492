import { Component, Inject, InjectionToken } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FlightOptions } from '../../../models/Flight';

export const FLIGHT_OPTIONS = new InjectionToken<number>('FlightOptions to show in a popup');

@Component({
	selector: 'tours-flight-details',
	templateUrl: './flight-details.component.html',
	styleUrls: ['./flight-details.component.scss'],
	providers: [
		{
			provide: FLIGHT_OPTIONS,
			useFactory: ({ flightDetails }: any) => flightDetails,
			deps: [MAT_DIALOG_DATA]
		}
	]
})
export class FlightDetailsComponent {
	constructor(@Inject(FLIGHT_OPTIONS) public flightOptions: FlightOptions) { }
}