import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/tours/src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { InvoiceStatus } from '../models/InvoiceStatus';
import { OrderStatus } from '../models/OrderStatus';

@Injectable()
export class StatusService {

	private readonly statusURL: string = `${environment.orderURL}/package_checkout/status`;

	constructor(private httpClient: HttpClient) { }

	public get(orderNumber: number, surname: string): Observable<OrderStatus> {

		const params = new HttpParams()
			.append("orderId", `${orderNumber}`)
			.append("surname", surname);

		const headers = new HttpHeaders({
			'Content-Type': 'application/x-www-form-urlencoded'
		});

		return this.httpClient.post<OrderStatus>(this.statusURL, params.toString(), { headers })
			.pipe(
				map(status => {
					if (status.paymentType === "liqpay") {
						status.invoices = status.invoices.map(this.addLinksToInvoices)
					}
					return status;
				})
			);
	}


	private addLinksToInvoices(invoice: InvoiceStatus): InvoiceStatus {
		if (invoice.status === 'issued') {
			invoice.link = `https://api.clevertravel.com.ua/payment/invoice/public/${invoice.id}/liqpay`;
		}
		return invoice;
	}
}
