import { InjectionToken, Provider } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { combineLatest, Observable } from "rxjs";
import { distinctUntilChanged, filter, map } from "rxjs/operators";
import { People } from "tours-lib";
import { COUNTRY } from "../../data/Country";
import { COUNTRY_INFO } from "../../data/CountryInfo";
import { ORIGIN } from "../../data/Origin";
import { ORIGIN_INFO } from "../../data/OriginInfo";
import { RESORT } from "../../data/Resort";
import { RESORT_INFO } from "../../data/ResortInfo";
import { Resort, Origin, Country } from "../../models/Locations";
import { SearchRequest } from "../../models/SearchRequest";

export const SEARCH_ROUTE_PARAMS = new InjectionToken<Observable<SearchRequest>>(
	'A stream with search parameters',
);

export const SEARCH_PROVIDER: Provider[] = [
	{
		provide: SEARCH_ROUTE_PARAMS,
		deps: [ActivatedRoute],
		useFactory: searchRouteParamsFactory,
	}
];

function searchRouteParamsFactory(route: ActivatedRoute): Observable<SearchRequest>{
	return combineLatest([route.params, route.queryParams])
		.pipe(
			map(([params, queryParams]) => ({ ...params, ...queryParams })),
			filter(hasRequiredFields),
			map(mapObjectToSearchRequest),
			distinctUntilChanged()
		);
}

function hasRequiredFields(params: any){
	const requiredFields = ['checkIn', 'checkInTo', 'durationFrom', 'durationTo', 'adults', 'origin']
	const allFieldNames = Object.getOwnPropertyNames(params);
	return requiredFields.every(element => allFieldNames.includes(element));
}

function mapObjectToSearchRequest(params: any): SearchRequest {
	console.log('Route change:', params)

	let destination;
	if (params.hasOwnProperty('resort') && RESORT_INFO.hasOwnProperty(params.resort))
		destination = RESORT_INFO[params.resort as RESORT] as Resort;
	else
		destination = COUNTRY_INFO[params.country as COUNTRY] as Country;

	return {
		origin: ORIGIN_INFO[params.origin as ORIGIN] as Origin,
		destination: destination,
		dateRange: {
			checkIn: params.checkIn,
			checkInTo: params.checkInTo
		},
		people: new People(params.adults, params.children),
		duration: {
			durationFrom: parseInt(params.durationFrom),
			durationTo: parseInt(params.durationTo)
		}
	}
}