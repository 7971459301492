<div class="grid-x invoice-list grid-padding-x">

	<div class="cell small-2 text-right">
		{{invoice.amount}} грн
	</div>

	<div class="cell small-5">
		<strong *ngIf="invoice.status === 'success'">Оплачено</strong>

		<ng-container *ngIf="invoice.status === 'issued'">
			<a [href]="invoice.link">
				<img src="//static.liqpay.ua/buttons/p1ru.radius.png" alt="Оплатить" />
			</a>
		</ng-container>
	</div>

</div>