import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CurrentCurrencyService } from 'tours-lib';
import { Surcharge } from '../../../models/Surcharge';

@Component({
	selector: 'tours-surcharges',
	templateUrl: './surcharges.component.html',
	styleUrls: ['./surcharges.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: SurchargesComponent,//forwardRef(() => CustomerInfoComponent),
			multi: true
		}/*, {
			provide: NG_VALIDATORS,
			useExisting: PassportComponent,//forwardRef(() => CustomerInfoComponent),
			multi: true
		}*/
	]
})
export class SurchargesComponent implements OnInit, ControlValueAccessor {
	@Input() surcharges!: Surcharge[];
	
	public readonly currentCurrency$ = this.currencyService.get();
	public surchargeArray: Surcharge[] = [];

	onChange = (array: Surcharge[]) => {};
	onTouched: () => void = () => { };

	constructor(private currencyService: CurrentCurrencyService) {}

	ngOnInit(): void {
		this.surchargeArray = this.surcharges.filter(surcharge => surcharge.mandatory);
	}

	writeValue(obj: any): void {
		this.surchargeArray = [...obj];
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	get sortedSurcharges(){
		return this.surcharges.sort((a, b) => {
			if (a.mandatory && !b.mandatory) return 1;
			if (!a.mandatory && b.mandatory) return -1;
			return 0;
		});
	}

	addSurcharge(surcharge: Surcharge){

		if (this.surchargeArray.indexOf(surcharge) >= 0) {
			this.surchargeArray = this.surchargeArray.filter(element => element != surcharge);
		} else {
			this.surchargeArray = [...this.surchargeArray, surcharge];
		}

		this.onChange(this.surchargeArray);
	}

}
