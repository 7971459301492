import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { People } from 'tours-lib';
import { FlightOptions } from '../../../models/Flight';
import { SearchBase } from '../../../models/SearchBase';
import { OfferId } from '../../../models/SearchOffer';
import { SearchRequest } from '../../../models/SearchRequest';
import { FlightDetailsComponent } from '../../../shared/components/flight-details/flight-details.component';
import { SEARCH_PROVIDER, SEARCH_ROUTE_PARAMS } from '../../../shared/providers/routeSearchParamsProvider';
import { SearchListService } from '../../services/search-list.service';

@Component({
	selector: 'tours-search-list',
	templateUrl: './search-list.component.html',
	styleUrls: ['./search-list.component.scss'],
	providers: [
		SEARCH_PROVIDER,
		SearchListService
	]
})
export class SearchListComponent extends SearchBase implements OnDestroy {

	private readonly searchState$ = this.listService.state$;
	private readonly offerSelect$ = new Subject<OfferId>();
	private readonly sink = new SubSink();

	readonly loading$ = this.listService.loading$;
	readonly searchResult$ = this.listService.searchResult$;
	readonly errors$ = this.listService.errors$;

	constructor(
		public router: Router,
		@Inject(SEARCH_ROUTE_PARAMS) readonly route$: Observable<SearchRequest>,
		private dialog: MatDialog,
		private listService: SearchListService
	) {
		super(router);

		this.sink.add(
			this.offerSelect$.asObservable()
				.pipe(
					switchMap(offerId => combineLatest([of(offerId), this.searchState$, this.route$])),
					map(([offerId, state, route]) => ({
						offer: offerId.id,
						tourOperatorId: offerId.operatorId,
						people: People.toOtpuskFormat(route.people),
						hash: state.hash
					}))
				)
				.subscribe(queryParams =>
					this.router.navigate(['/reservation/'], { queryParams })
				)
		)
	}
	ngOnDestroy(): void {
		this.sink.unsubscribe();
	}

	public loadMore(event: Event) {
		this.listService.loadMore();
	}

	public offerSelected(data: OfferId) {
		this.offerSelect$.next(data);
	}

	public flightSelected(flight: FlightOptions) {
		this.dialog.open(FlightDetailsComponent, {
			data: { flightDetails: flight }
		});
	}
}