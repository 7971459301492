import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { Offer } from '../../models/Offer';
import { environment } from 'projects/tours/src/environments/environment';
import { map, switchMap } from 'rxjs/operators';
import { HotelDetails } from '../../models/HotelDetails';
import { HotelService } from '../../shared/services/hotel.service';
import { PackageOrder } from '../models/PackageOrder';
import { OrderNumberSurname } from '../models/OrderNumberSurname';

export interface OfferData {
	offer: Offer;
	hotel: HotelDetails;
}

@Injectable()
export class OfferService {

	private offerURL: string = `${environment.apiURL}/package/offer`;
	private createOrderURL: string = `${environment.orderURL}/package_checkout/create`;

	private LOCATION_HEADERS_NAME: string = 'Location';

	constructor(public httpClient: HttpClient, private hotelService: HotelService) { }

	public getData(offer: string, hash: string, people: string, tourOperatorId: string): Observable<OfferData> {

		const httpParams = new HttpParams()
			.append('offer', offer)
			.append('hash', hash)
			.append('people', people);

		return this.httpClient.get<Offer>(this.offerURL, {
			params: httpParams,
			responseType: "json"
		}).pipe(
			switchMap(value => combineLatest([of(value), this.hotelService.getDetails(value.hotelId)])),
			map(([offer, hotel]) => ({ offer, hotel }))
		);
	}

	/*private makeOrderOffer(offer: Offer, hotel: HotelDetails){
		return {
			...offer,
			hotelName: hotel.name,
			surcharges: [],
			departureDateTime: this.selectOutboundFlight(offer.flightOptions.outboundFlights),
			returnDateTime: this.selectReturnFlight(offer.flightOptions.outboundFlights)
		}
	}*/

	public createOrder(packageOrder: PackageOrder): Observable<OrderNumberSurname> {
		return this.httpClient.post(this.createOrderURL, packageOrder, {
			observe: "response",
			responseType: "text"
		}).pipe(
			map(httpResponse => {
				if (httpResponse.headers.has(this.LOCATION_HEADERS_NAME))
					return httpResponse.headers.get(this.LOCATION_HEADERS_NAME) as string;
				throw Error('No correct headers found');
			}),
			map(url => {
				const match = decodeURI(url).match(/.+\/status\/([\d]+)\/([^\/]+)/);

				if (match && match?.length >= 3) {
					return {
						id: parseInt(match[1]),
						surname: match[2]
					}
				}

				throw Error('Unparsable url');
			})
		);
	}
}
