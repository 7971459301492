<ng-container>

	<div class="show-for-small-only">
		<tours-photos-line [photos]="hotel.photos" [thumbnails]="hotel.thumbnailPhotos"></tours-photos-line>
	</div>

	<div>
		<h1>{{hotel.name}}</h1>
		<app-stars [stars]="hotel.stars"></app-stars>
	</div>

	<div class="address">
		<small [innerHTML]="hotel.address"></small>
	</div>

	<div class="hotel-images-map show-for-medium">

		<tours-hotel-photos [photos]="hotel.photos" [thumbnails]="hotel.thumbnailPhotos"></tours-hotel-photos>

		<ng-container *ngIf="!!hotel.coordinates">
			<tours-map [coordinates]="hotel.coordinates"></tours-map>
		</ng-container>

	</div>
	<tours-hotel-description [description]="hotel.description"></tours-hotel-description>

	<tours-search></tours-search>

</ng-container>