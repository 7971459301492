<div class="offer grid-x grid-padding-y grid-padding-x" [ngClass]="{'instant': offer.instant}">
	<div class="cell small-12 medium-8 large-10">
		<div class="grid-x grid-padding-x">
			<div class="cell small-12 medium-8 large-4">
				<span class="label">Номер</span>
				<div class="with-additional-info">
					<span>{{ offer.room }}</span>
					<div>
						<small>
							Для {{ offer.adults }} взрослых <span *ngIf="!!offer.children"> и {{offer.children}}
								детей</span>
						</small>
					</div>
				</div>
			</div>
			<div class="cell small-3 medium-4 large-2 food">
				<span class="label">Питание</span><br />
				{{ offer.food | food }}
			</div>
			<div class="cell small-3 medium-4 large-2">
				<span class="label">Туроператор</span><br />
				{{ offer.operatorId | tourOperators }}
			</div>
			<div class="cell small-3 medium-4 large-2">
				<span class="label">Длительность</span><br />
				{{ offer.duration }} дней
			</div>
			<div class="cell small-3 medium-4 large-2">
				<span class="label">Вылет</span><br />
				<div class="with-additional-info">
					<span>{{offer.date}}</span>
					<div>
						<small class="flights-timetable-link" (click)="showFlightTimetable(offer.flightOptions)">
							Время перелетов
						</small>
					</div>
				</div>
			
			</div>
		</div>
		<div *ngIf="offer.instant" class="text-center show-for-medium">
			<i class="fas fa-check-circle"></i>&nbsp;Моментальное&nbsp;подтверждение
		</div>
	</div>
	<div class="cell small-12 medium-4 large-2">
		<app-price [price]="offer.extendedPrice"></app-price>
		<div (click)="goToReservation()" class="button">
			Купить
		</div>
		<div *ngIf="offer.instant" class="text-center show-for-small-only">
			<i class="fas fa-check-circle"></i>&nbsp;Моментальное&nbsp;подтверждение
		</div>
	</div>
</div>