import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Currency } from '../../models/Currency';
import { Price } from '../../models/PriceMap';

@Component({
	selector: 'lib-price-amount',
	templateUrl: './price-amount.component.html',
	styleUrls: ['./price-amount.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PriceAmountComponent {

	@Input() public amount!: number;
	@Input() public currency!: Currency;

	constructor() { }

	@Input() set price(price: Price) {
		this.amount = price.price;
		this.currency = price.currency;
	}

}
