import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToursLibModule } from 'tours-lib';
import { OverlayModule } from '@angular/cdk/overlay';
import { DurationSelectComponent } from './components/duration-select/duration-select.component';
import { DestinationSelectComponent } from './components/destination-select/destination-select.component';
import { ResortListSelectComponent } from './components/resort-list-select/resort-list-select.component';
import { CalentimComponent } from './components/calentim/calentim.component';
import { OriginSelectComponent } from './components/origin-select/origin-select.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { HttpClientModule } from '@angular/common/http';
import { AutocompleteService } from './services/autocomplete.service';
import { CountriesFlagPipe } from './pipes/countries-flag.pipe';

@NgModule({
	declarations: [
		SearchBarComponent,
		DurationSelectComponent,
		DestinationSelectComponent,
		ResortListSelectComponent,
		CalentimComponent,
		OriginSelectComponent,
		AutocompleteComponent,
  CountriesFlagPipe
	],
	imports: [
		CommonModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		ToursLibModule,
		OverlayModule
	],
	exports: [
		SearchBarComponent
	],
	providers: [
		AutocompleteService
	]
})
export class SearchBarModule { }
