import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Flight } from '../../../models/Flight';


@Component({
	selector: 'tours-flight-select',
	templateUrl: './flight-select.component.html',
	styleUrls: ['./flight-select.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: FlightSelectComponent,//forwardRef(() => CustomerInfoComponent),
			multi: true
		}/*, {
			provide: NG_VALIDATORS,
			useExisting: PassportComponent,//forwardRef(() => CustomerInfoComponent),
			multi: true
		}*/
	]
})
export class FlightSelectComponent implements OnInit, ControlValueAccessor {

	@Input() options!: Flight[];
	onTouched = () => { };

	flightControl = new FormControl();

	constructor() { }

	writeValue(obj: any): void {
		this.flightControl.setValue(obj);
	}

	registerOnChange(fn: any): void {
		this.flightControl.valueChanges.subscribe(fn);
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	ngOnInit(): void {
		if(this.options.length > 0){
			this.flightControl.setValue(this.options[0]);
		}
	}

}
