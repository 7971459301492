<div>
	<input type="text" [formControl]="input" />
</div>
<ng-container *ngIf="(results$ | async) as results">
	<ul class="autocomplete-results" >
		<li *ngFor="let item of results" class="selectable-item" (click)="handleItemClick(item)">
			<span class="flag-icon {{item.countryId | countriesFlag}}" *ngIf="!item.resortIds"></span>
			<i 
				[ngClass]="{
						'far fa-building': item.hotelIds != null,
						'fas fa-map-marker': item.hotelIds == null && item.resortIds != null
					}"
				class="color-gray"
			></i>
			<span ng-bind="item.name">{{item.name}}</span>&nbsp;<small class="color-gray">{{item.address}}</small>
		</li>
	</ul>
</ng-container>