import { NgModule } from '@angular/core';
import { FooterComponent } from './components/footer/footer.component';
import { LangSelectComponent } from './components/lang-select/lang-select.component';
import { CommonModule } from '@angular/common';
import { ContactsDialog, HeaderComponent } from './components/header/header.component';
import { MatDialogModule } from '@angular/material/dialog';
import { PeopleSelectComponent } from './components/people-select/people-select.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';
import { PopoverComponent } from './components/popover/popover.component';
import { LoadingAirplaneComponent } from './components/loading-airplane/loading-airplane.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { CurrencySelectComponent } from './components/currency-select/currency-select.component';
import { PriceComponent } from './components/price/price.component';
import { StarsComponent } from './components/stars/stars.component';
import { LoadMoreButtonComponent } from './components/load-more-button/load-more-button.component';
import { CustomerInfoComponent } from './components/customer-info/customer-info.component';
import { MatInputModule } from '@angular/material/input';
import { PassportComponent } from './components/passport/passport.component';
import { PassportSelectComponent } from './components/passport-select/passport-select.component';
import { BeautifulRadioComponent } from './components/beautiful-radio/beautiful-radio.component';
import { NgxMaskModule } from 'ngx-mask';
import { TosComponent } from './components/tos/tos.component';
import { LoadingCirclesComponent } from './components/loading-circles/loading-circles.component';
import { BasicPriceComponent } from './components/basic-price/basic-price.component';
import { DevDirective } from './directives/dev.directive';
import { PriceAmountComponent } from './components/price-amount/price-amount.component';
import { CurrencyPipe } from './pipes/currency.pipe'

@NgModule({
	declarations: [
		FooterComponent,
		LangSelectComponent,
		HeaderComponent,
		PeopleSelectComponent,
		CurrencySelectComponent,
		PopoverComponent,
		LoadingAirplaneComponent,
		SpinnerComponent,
		PriceComponent,
		StarsComponent,
		LoadMoreButtonComponent,
		CustomerInfoComponent,
		BeautifulRadioComponent,
		PassportComponent,
		PassportSelectComponent,
		TosComponent,
		LoadingCirclesComponent,
		BasicPriceComponent,
		DevDirective,
		PriceAmountComponent,
		CurrencyPipe
	],
	imports: [
		CommonModule,
		MatDialogModule,
		FormsModule,
		ReactiveFormsModule,
		OverlayModule,
		MatInputModule,
		NgxMaskModule.forRoot()
	],
	exports: [
		FooterComponent,
		HeaderComponent,
		PeopleSelectComponent,
		CurrencySelectComponent,
		PopoverComponent,
		LoadingAirplaneComponent,
		PriceComponent,
		StarsComponent,
		SpinnerComponent,
		LoadMoreButtonComponent,
		CustomerInfoComponent,
		BeautifulRadioComponent,
		PassportComponent,
		PassportSelectComponent,
		TosComponent,
		LoadingCirclesComponent,
		BasicPriceComponent,
		DevDirective,
		PriceAmountComponent
	],
	entryComponents: [
		ContactsDialog
	]
})
export class ToursLibModule { }
