import { Component, Input } from '@angular/core';

@Component({
	selector: 'tours-hotel-photos',
	templateUrl: './hotel-photos.component.html',
	styleUrls: ['./hotel-photos.component.scss']
})
export class HotelPhotosComponent {

	@Input() photos!: string[];
	@Input() thumbnails!: string[];

	constructor() { }

	get mainPhoto(): string{
		return this.photos[0];
	}

}
