<div class="passport" [formGroup]="passportFormGroup">

	<div class="gender">
		<app-beautiful-radio [values]="genderTypes" formControlName="sex"></app-beautiful-radio>
	</div>

	<div class="basic-info grid-x grid-margin-x">

		<div class="name small-6 medium-4 cell">
			<label>Name
				<input type="text" formControlName="name" />
			</label>
			<div *ngIf="name.invalid && (name.dirty || name.touched)" class="validation-error">
				<p  i18n="name form error">Name is invalid</p>
			</div>
		</div>

		<div class="surname small-6 medium-4 cell">
			<label>Surname
				<input type="text" formControlName="surname" />
			</label>
			<div *ngIf="surname.invalid && (surname.dirty || surname.touched)" class="validation-error">
				<p i18n="surname form error">Surname is invalid</p>
			</div>
		</div>

		<div class="birthday small-6 medium-4 cell">
			<label>Birthday
				<input type="text" formControlName="birthday" mask="00/00/0000" [showMaskTyped]="true"/>
			</label>
			<div *ngIf="birthday.invalid && (birthday.dirty || birthday.touched)" class="validation-error">
				<p i18n="birthday form error">Birthday date is invalid</p>
			</div>
		</div>
	</div>

	<div class="passport-details grid-x grid-margin-x">

		<div class="passport-number small-6 large-3 cell">
			<label>Passport number
				<input type="text" formControlName="passport" />
			</label>
			<div *ngIf="passport.invalid && (passport.dirty || passport.touched)" class="validation-error">
				<p i18n="passport number form error">Passport number is invalid</p>
			</div>
		</div>

		<div class="citizenship small-6 large-3 cell">
			<label>
				Citizenship
				<select formControlName="citizenship">
					<option *ngFor="let option of countryCodes | keyvalue" [value]="option.key">{{option.value}}
					</option>
				</select>
			</label>
			<div *ngIf="citizenship.invalid && (citizenship.dirty || citizenship.touched)" class="validation-error">
				<p i18n="citizenship is invalid">Citizenship field is invalid</p>
			</div>
		</div>

		<div class="valid-until small-6 large-3 cell">
			<label>Valid until
				<input type="text" formControlName="valid" mask="00/00/0000" [showMaskTyped]="true" />
			</label>
			<div *ngIf="valid.invalid && (valid.dirty || valid.touched)" class="validation-error">
				<p i18n="valid until form">Valid until date is invalid</p>
			</div>
		</div>

		<div class="issed-by small-6 large-3 cell">
			<label>Issued by
				<input type="text" formControlName="issued" />
			</label>
			<div *ngIf="issued.invalid && (issued.dirty || issued.touched)" class="validation-error">
				<p i18n="issuer form error">Passport issuer is invalid</p>
			</div>
		</div>
	</div>

</div>