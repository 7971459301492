import { InjectionToken } from "@angular/core";

export const TOUR_OPERATORS = new InjectionToken<{ [key: string]: { name: string, url: string } }>('TourOperators Map', {
	factory: () => {
		return tourOperatorsMap;
	}
});

const tourOperatorsMap = {
	"3019": {
		"name": "Adria Hit",
		"url": "http:\/\/www.adriahit.com"
	},
	"759": {
		"name": "Alf",
		"url": "http:\/\/www.alf-ua.com\/"
	},
	"717": {
		"name": "Anextour",
		"url": "http:\/\/anextour.com.ua\/"
	},
	"3306": {
		"name": "Aristeya",
		"url": "http:\/\/www.aristeya.com.ua"
	},
	"1416": {
		"name": "Coral Travel",
		"url": "http:\/\/www.coraltravel.ua\/"
	},
	"3336": {
		"name": "Феєрія Мандрів",
		"url": "https:\/\/feerie.com.ua"
	},
	"3021": {
		"name": "Kompas",
		"url": "https:\/\/kompastour.com.ua\/"
	},
	"2835": {
		"name": "JoinUp!",
		"url": "http:\/\/joinup.ua\/"
	},
	"3066": {
		"name": "Kandagar",
		"url": "http:\/\/www.kandagar.net\/"
	},
	"1897": {
		"name": "Lyubosvit",
		"url": "http:\/\/lyubosvit.com.ua\/"
	},
	"856": {
		"name": "Mouzenidis",
		"url": "http:\/\/www.mouzenidis-travel.ru"
	},
	"3": {
		"name": "Oasis",
		"url": "http:\/\/www.oasis.com.ua\/"
	},
	"237": {
		"name": "Orbita",
		"url": "http:\/\/orbita.ua\/"
	},
	"269": {
		"name": "Pegas",
		"url": "http:\/\/pegast.com.ua\/"
	},
	"3311": {
		"name": "Sonata",
		"url": "http:\/\/sonata-travel.com\/"
	},
	"520": {
		"name": "TCC",
		"url": "http:\/\/tcc.com.ua\/"
	},
	"2": {
		"name": "TEZ Тур",
		"url": "http:\/\/www.tez-tour.com\/"
	},
	"2700": {
		"name": "TPG",
		"url": "http:\/\/www.tpg.ua\/"
	},
	"3331": {
		"name": "TUI",
		"url": "http:\/\/b2b.tui.ua\/search_tour"
	},
	"3321": {
		"name": "UGC",
		"url": "http:\/\/ugctravel.com\/"
	},
	"3341": {
		"name": "VediTour",
		"url": "http:\/\/veditour.com.ua\/"
	},
	"3316": {
		"name": "West Travel",
		"url": "http:\/\/www.w-t.com.ua\/"
	},
	"3237": {
		"name": "Zeus",
		"url": ""
	},
	"3342": {
		"name": "Центр",
		"url": "http:\/\/www.cntr.com.ua\/"
	},
	"3344": {
		"name": "Alliance",
		"url": "http:\/\/www.alliance.ua\/"
	},
	"3345": {
		"name": "Albena Travel",
		"url": "http:\/\/www.albena.com.ua\/"
	},
	"3348": {
		"name": "GTH",
		"url": "https:\/\/gth.com.ua\/"
	}
}