<div class="grid-container">
	<ng-container *ngIf="(hotelDetails$ | async) as hotel; else loading">
		<tours-hotel [hotel]="hotel"></tours-hotel>
	</ng-container>

	<ng-template #loading>
		<div class="align-self-middle align-self-center">
			<app-loading-circles></app-loading-circles>
		</div>
	</ng-template>
</div>