<p [innerHTML]="description.basic"></p>

<div class="description-tile" *ngIf="description.location">
	<h4><i class="far fa-compass"></i>&nbsp;Расположение</h4>
	<p [innerHTML]="description.location"></p>
</div>

<div class="description-tile" *ngIf="description.infrastructure">
	<h4><i class="far fa-thumbs-up"></i>&nbsp;Услуги в отеле</h4>
	<p [innerHTML]="description.infrastructure"></p>
</div>

<div class="description-tile" *ngIf="description.beach">
	<h4><i class="far fa-sun"></i>&nbsp;Пляж</h4>
	<p [innerHTML]="description.beach"></p>
</div>

<div class="description-tile" *ngIf="description.children">
	<h4><i class="fas fa-child"></i>&nbsp;Для детей</h4>
	<p [innerHTML]="description.children"></p>
</div>

<div class="description-tile" *ngIf="description.sports">
	<h4><i class="fas fa-table-tennis"></i>&nbsp;Развлечения и спорт</h4>
	<p [innerHTML]="description.sports"></p>
</div>

<div class="description-tile" *ngIf="description.room">
	<h4><i class="fas fa-bed"></i>&nbsp;Номера</h4>
	<p [innerHTML]="description.room"></p>
</div>