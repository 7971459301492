<mat-dialog-content class="mat-typography">

	<div *ngIf="tos$ | async as tosContent; else loading">
		<span [innerHTML]="tosContent"></span>
	</div>
	<ng-template #loading>
		<app-loading-circles></app-loading-circles>
	</ng-template>

</mat-dialog-content>

<mat-dialog-actions align="start">

	<button class="submit-button" [mat-dialog-close]="true" cdkFocusInitial *ngIf="tos$ | async">Submit</button>
	<button mat-dialog-close>Cancel</button>

</mat-dialog-actions>