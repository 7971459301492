import { Inject, Injectable } from '@angular/core';
import { environment } from 'projects/tours/src/environments/environment';
import { combineLatest, EMPTY, Observable, of, ReplaySubject, Subject, throwError } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from 'tours-lib';
import { SearchRequest } from '../../models/SearchRequest';
import { SearchResult } from '../../models/SearchResult';
import { SearchState } from '../../models/SearchState';
import { SEARCH_ROUTE_PARAMS } from '../../shared/providers/routeSearchParamsProvider';
import { SearchService } from '../../shared/services/search.service';

@Injectable()
export class SearchListService extends UnsubscribeOnDestroy {

	private readonly searchState$: ReplaySubject<SearchState> = new ReplaySubject(1);
	private readonly loadingStream$ = new ReplaySubject(1);
	private readonly errorsStream$: ReplaySubject<string> = new ReplaySubject(1);
	private readonly loadMore$ = new Subject();

	public readonly searchResult$: Observable<SearchResult>;

	RETRY_INTERVAL_MS = environment.searchRetryInterval;

	constructor(
		@Inject(SEARCH_ROUTE_PARAMS) private readonly route$: Observable<SearchRequest>,
		private searchService: SearchService,
	) {
		super();

		this.sink.add(
			route$.pipe(
				tap(() => {
					this.loadingStream$.next(true);
					this.errorsStream$.next(undefined);
				}),
				switchMap(value => 
					this.searchService.search(value)
						.pipe(
							catchError(this.handleError.bind(this))
						)
					),
			).subscribe(
				hash => {
					console.log(hash);
					this.searchState$.next(new SearchState(hash));
				}
			)
		);

		this.searchResult$ = this.searchState$
			.pipe(
				switchMap(state => 
					combineLatest([
						of(state),
						this.searchService.queryResult(state)
							.pipe(
								catchError(this.handleError.bind(this))
							)
					])
				),
				tap(([state, result]) => {
					if (!result.completed) {
						setTimeout(() => this.searchState$.next(state), this.RETRY_INTERVAL_MS);
					}
				}),
				map(([state, result]) => result),
				tap(result => {
					this.loadingStream$.next(!result.completed)
				}),
				//catchError(this.handleError.bind(this))
			);

		this.sink.add(
			this.loadMore$.pipe(
				withLatestFrom(this.searchState$),
				switchMap(([click, state]) => 
					combineLatest([
						of(state),
						this.searchService.loadMore(state.nextBatch())
							.pipe(
								catchError(this.handleError.bind(this))
							)
					])
				)
			).subscribe(
				([state, result]) => {
					this.searchState$.next(state.nextBatch());
				}
			)
		);
	}

	public loadMore() {
		this.loadingStream$.next(true);
		this.errorsStream$.next(undefined);
		this.loadMore$.next();
	}

	get state$() {
		return this.searchState$.asObservable();
	}

	get loading$() {
		return this.loadingStream$.asObservable();
	}

	get errors$() {
		return this.errorsStream$.asObservable();
	}

	private handleError(error: any) {
		this.loadingStream$.next(false);
		this.errorsStream$.next(error);
		return EMPTY;
	}
}