<div class="surcharges-block" *ngIf="surcharges && surcharges.length > 0">

	<div class="text-center">
		<h4>Додаткові послуги</h4>
	</div>

	<div 
		class="surcharge grid-x"
		*ngFor="let surcharge of sortedSurcharges"
		[ngClass]="{
			'added': surchargeArray.indexOf(surcharge) >= 0,
			'mandatory': surcharge.mandatory === true
		}"
	>

		<div class="cell small-8 medium-9 large-8">
			<p>{{surcharge.name}}</p>
			<small>{{surcharge.description}}</small>
			<div>
				<a [href]="surcharge.link" target="_blank" class="additional-info">
					<i class="fas fa-info-circle"></i>
					детальна інформація
				</a>
			</div>
		</div>

		<div class="cell small-4 medium-3 large-2 large-offset-2 text-center">
			<div class="surcharge-price" (click)="addSurcharge(surcharge)">

				<div>
					<ng-container *ngIf="(currentCurrency$ | async) as currency">
						<lib-price-amount [price]="surcharge.extendedPrice[currency]"></lib-price-amount>
					</ng-container>
					<small *ngIf="surcharge.perPerson"> з&nbsp;особи</small>
				</div>
				<div class="add-text">
					<small>+&nbsp;додати</small>
				</div>

				<div class="already-added-text">
					<small><i class="fas fa-check"></i>&nbsp;додано</small>
				</div>
			</div>
		</div>

	</div>
</div>