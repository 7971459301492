import { Overlay } from '@angular/cdk/overlay';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { TosComponent } from 'tours-lib';
import { PackageContract } from '../../models/PackageContract';
import { OfferData, OfferService } from '../../services/offer.service';

@Component({
	selector: 'tours-reservation',
	templateUrl: './reservation.component.html',
	styleUrls: ['./reservation.component.scss']
})
export class ReservationComponent implements OnInit, OnDestroy {

	@Input() data!: OfferData;

	readonly orderForm = this.fb.group({
		customerInfo: [null, [Validators.required]],
		passports: [[]],
		surcharges: [[]],
		outboundFlight: [null],
		returnFlight: [null],
		payment: [null],
		coupon: [undefined]
	});

	sink = new SubSink()

	packageContract$!: Observable<PackageContract>;

	discountAmount: number = 0;

	readonly overlayRef = this.overlay.create({
		positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
		hasBackdrop: true
	});

	constructor(
		private fb: FormBuilder,
		public dialog: MatDialog,
		private overlay: Overlay,
		private router: Router,
		private offerService: OfferService
	) { }

	ngOnDestroy(): void {
		this.sink.unsubscribe();
	}

	ngOnInit(): void {

		this.packageContract$ = combineLatest([
			of(this.data),
			this.orderForm.get('surcharges')!.valueChanges,
			this.orderForm.get('outboundFlight')!.valueChanges,
			this.orderForm.get('returnFlight')!.valueChanges,
		]).pipe(
			map(([data, surcharges, outboundFlight, returnFlight]) => ({
				...data.offer,
				hotelName: data.hotel.name,
				departureDateTime: outboundFlight.departure,
				returnDateTime: returnFlight.departure,
				surcharges
			})),
			shareReplay(1)
		);

		//Need to subscribe before template rendering to obtein the first value
		this.sink.add(this.packageContract$.subscribe(value => { }));

		this.orderForm.patchValue({
			surcharges: this.data.offer.surcharges.filter(value => value.mandatory),
			outboundFlight: this.data.offer.flightOptions.outboundFlights[0],
			returnFlight: this.data.offer.flightOptions.returnFlights[0]
		});
	}

	public submitOrder() {

		if (this.orderForm.valid) {

			const dialogRef = this.dialog.open(TosComponent, {
				data: { url: 'https://api.clevertravel.com.ua/package/contract' }
			});

			this.sink.add(
				dialogRef.afterClosed()
					.pipe(
						filter(value => value === true),
						//tap(_ => this.overlayRef.attach()),
						switchMap( _ => this.packageContract$),
						switchMap( offer => {
							const { customerInfo, passports, payment, coupon } = this.orderForm.value;
							return this.offerService.createOrder({
								customer: customerInfo,
								passports,
								offer,
								payment,
								coupon
							});
						})
					)
					.subscribe(value => {
						console.log(value);
						//this.overlayRef.detach();
						this.router.navigate(['/status', value.id, value.surname]);	
					})
			);
		} else {
			this.orderForm.markAllAsTouched();
		}

	}

	public setDiscount(discount: number) {
		this.discountAmount = discount;
	}

	get customerInfo() {
		return this.orderForm.get('customerInfo') as FormControl;
	}

	get passports() {
		return this.orderForm.get('passports') as FormArray;
	}

}
