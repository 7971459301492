import { AbstractControl, FormArray, ValidationErrors, ValidatorFn } from "@angular/forms";

export const passportOptionsValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
	
	const type = control.get('type');
	const passportsArray = control.get('passports') as FormArray;

	if (
		type && type.value === 'delayedPassports'
	){
		return null;
	}
	
	if(
		type && type.value === 'instantPassports'
		&& Array.isArray(passportsArray.controls)
		&& passportsArray.controls.length > 0
		&& passportsArray.controls.reduce((accumulator, current) => accumulator && current.valid, true)
	) {
		return null
	}

	return { invalidForm: { valid: false, message: "passport-select is in inconsistent state" } };
}