<div class="grid-x">
	<div class="cell auto">
		<div class="smile-loader">
			<div class="air">
				<div class="cloud first"></div>
				<div class="cloud second show-for-medium"></div>
				<div class="cloud third show-for-medium"></div>

				<div class="sunshine">
					<div class="sun"></div>
				</div>
				<div class="airspace">
					<div class="airplane"></div>
				</div>
			</div>
			<div class="water">
				<div class="wave first-wave"></div>
				<div class="wave second-wave"></div>
				<div class="wave third-wave"></div>
			</div>
		</div>
	</div>
</div>
<div class="loading-text">
	<div class="prefix">
		<app-spinner></app-spinner>
	</div>
	<div class="text">
		<p>Мы производим поиск туров по базам данных всех туроператоров.</p>
		<small>В сезон поиск может занимать около минуты.</small>
	</div>
</div>