<div class="payment block-bottom-margin" *ngIf="(contract$ | async) as data">
	<div class="text-center">
		<h4>Спосіб оплати</h4>
	</div>

	<ng-container *ngIf="!loading">

		<div class="payment-filter grid-x align-center" *ngIf="minPrepayValue">
			<div class="payment-option cell small-6 medium-4 large-3" (click)="selectShowPrepay(false)">
				<div [ngClass]="{'active': showPrepayVal === false }">
					<div class="hide-for-small-only payment-label">Сплатити всю суму</div>
					<div class="show-for-small-only payment-label">Повна вартість</div>
					<div class="price">
						{{data!.extendedPrice['UAH'].price}} грн
					</div>
				</div>
			</div>
			<div class="payment-option cell small-6 medium-4 large-3" (click)="selectShowPrepay(true)">
				<div [ngClass]="{'active': showPrepayVal === true }">
					<div class="hide-for-small-only payment-label">Внести передоплату</div>
					<div class="show-for-small-only payment-label">Передоплата</div>
					<div class="price">
						від {{minPrepayValue}} грн
					</div>
				</div>
			</div>
		</div>

		<div class="grid-x align-center" *ngFor="let paymentMethod of paymentMethods" (click)="selectPaymentMethod(paymentMethod)">
		
			<div class="small-1 cell">
				<label class="radio-container float-center">
					<input type="radio" [checked]="paymentMethod === selectedPaymentMethod" disabled/>
					<div class="checkmark"></div>
				</label>
			</div>
			<div class="small-11 medium-10 large-7 cell">
				<p [innerHTML]="paymentMethodsGlossary[paymentMethod.method].name"></p>
				<small>{{ paymentMethodsGlossary[paymentMethod.method].description }}</small>
			</div>
		</div>

	</ng-container>

	<ng-container *ngIf="loading">
		<app-spinner></app-spinner>
	</ng-container>
</div>