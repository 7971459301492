import { Component, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as dayjs from 'dayjs';
import { SearchOffer } from 'projects/tours/src/app/models/SearchOffer';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { PriceMap } from 'tours-lib';
import { HotelSearchService } from '../../../services/hotel-search.service';


@Component({
	selector: 'tours-days',
	templateUrl: './days.component.html',
	styleUrls: ['./days.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: DaysComponent,
			multi: true
		}
	]
})
export class DaysComponent implements ControlValueAccessor, OnInit, OnDestroy {

	selectedValue?: string;

	private ISO_FORMAT = "YYYY-MM-DD";

	onChange = (value?: string) => { };
	onTouch = () => { };

	sink = new SubSink();

	public readonly days$: Observable<DayPrice[]>;
	public readonly loading$ = this.hotelSearchService.loading$;

	constructor(private hotelSearchService: HotelSearchService) {
		this.days$ = this.hotelSearchService.searchResult$
			.pipe(
				filter(value => value.offers.length > 0),
				map(({ offers }) => {
					const [minDateEpoch, maxDateEpoch] = offers
						.map(offer => {
							return Math.floor(Date.parse(offer.date) / 1000);
						})
						.reduce(([prevMin, prevMax], current) =>
							[Math.min(prevMin, current), Math.max(prevMax, current)], [Infinity, -Infinity]
						);

					const startOfWeek = dayjs.unix(minDateEpoch).startOf('week');
					const endOfWeek = dayjs.unix(maxDateEpoch).endOf('week');

					const days: DayPrice[] = [];

					let day = startOfWeek.clone();
					do {
						const dayInISOFormat = day.format(this.ISO_FORMAT);
						const minOffer = offers
							.filter(offer => offer.date === dayInISOFormat)
							.reduce(function (accumulator: SearchOffer | undefined, current: SearchOffer) {
								if (!accumulator) return current;
								if (current.extendedPrice.UAH.price > accumulator.extendedPrice.UAH.price)
									return accumulator;
								return current
							}, undefined);

						days.push({
							price: minOffer?.extendedPrice,
							date: day,
							value: dayInISOFormat
						});

						day = day.add(1, 'day');

					} while (day.isBefore(endOfWeek, 'day') || day.isSame(endOfWeek, 'day') );

					return days;
				})
			);

		this.sink.add(this.days$.subscribe(
			days => {
				const cheapestDay = days
					.filter(day => !!day.price)
					.reduce(
						function (accumulator: DayPrice | undefined, current: DayPrice) {

							if (!accumulator) return current;

							if (accumulator.price!.UAH.price > current.price!.UAH.price)
								return current;
							return accumulator;

						},
						undefined
					);

				cheapestDay && this.daySelected(cheapestDay);
			}
		));
			
	}
	ngOnDestroy(): void {
		this.sink.unsubscribe();
	}

	ngOnInit(): void {}

	writeValue(obj: string): void {
		this.selectedValue = obj;
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouch = fn;
	}


	public daySelected(day: DayPrice) {
		this.selectedValue = day.value;
		this.onTouch();
		this.onChange(day.value);
	}

}


interface DayPrice {
	price?: PriceMap,
	date: dayjs.Dayjs,
	value: string;
}