import { Injectable, OnDestroy } from "@angular/core";
import { SubSink } from "subsink";

@Injectable()
export abstract class UnsubscribeOnDestroy implements OnDestroy {

	protected sink = new SubSink();

	ngOnDestroy(): void {
		this.sink.unsubscribe();
	}
}