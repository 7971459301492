import { InjectionToken } from "@angular/core";

export const WINDOW = new InjectionToken<Window>('window', {
	
	factory: () => {

		if (typeof window !== 'undefined')
			return window;

		return {
			innerWidth: 1024 //Needed for smart-search-input
		} as Window;

	}

});