import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-stars',
	templateUrl: './stars.component.html',
	styleUrls: ['./stars.component.scss']
})
export class StarsComponent implements OnInit {

	@Input() stars!: number;

	public starsArray: number[];

	constructor() {
		this.starsArray = [];
	}

	ngOnInit(): void {
		this.starsArray = Array(this.stars).fill(0).map((x, i) => i + 1);
	}

}
