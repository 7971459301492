import { InjectionToken } from "@angular/core";

export const PAYMENT_METHOD_GLOSSARY = new InjectionToken<{ [key: string]: { name: string, description: string } }>(
	'Description for different payment methods',
	{
		factory: () => ({
			'liqpay': {
				name: '<span class="cc-icon"></span>Оплата картою Visa або MasterCard будь-якого банку',
				description: 'Оплата відбувається на захищеній сторінці еквайрингу LiqPay.'
			},
			'cash': {
				name: 'На розрахунковий рахунок',
				description: 'Оплата готівкою можлива через касу банку або термінал.'
			}
		})
	}
);