import { Component } from '@angular/core';
import { Currency } from '../../models/Currency';
import { CurrentCurrencyService } from '../../services/current-currency.service';

@Component({
	selector: 'app-currency-select',
	templateUrl: './currency-select.component.html',
	styleUrls: ['./currency-select.component.scss']
})
export class CurrencySelectComponent {

	currencyList: Currency[] = Object.values(Currency);

	constructor(private currentCurrencyService: CurrentCurrencyService) { }

	setCurrency(currencyOption: Currency){
		this.currentCurrencyService.set(currencyOption);
	}

	get selectedCurrency(){
		return this.currentCurrencyService.get();
	}

}